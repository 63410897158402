import { format } from 'date-fns'
import { padStart } from 'lodash'
import { AbstractModel } from '../base'
import { Client, Site, type IClient, type ISite } from '../client'
import {
  FormField,
  FormFieldConstraint,
  FormFieldConstraintCondition,
  FormFieldOption,
  FormFieldSubTypeEnum
} from '../form'
import type {
  IFormField,
  IFormFieldConstraint,
  IFormFieldConstraintCondition,
  IFormFieldOption
} from '../form'
import { FormSection, type IFormSection } from '../form'

import { Form, type IForm } from '../form'
import { IncidentReport, ReportActivityStatusEnum, type IIncidentReport } from '../report'
import {
  PostOrderInstructionSectionTypeEnum,
  PostOrderInstructionsSectionSubmissionStateEnum,
  PostOrderInstructionStateEnum,
  PostOrderInstructionTypeEnum
} from './post-order.constants'
import type {
  IPostOrder,
  IPostOrderInstructionsField,
  IPostOrderInstructionsFieldData,
  IPostOrderInstructionsFieldOption,
  IPostOrderInstructionsFieldOptionData,
  IPostOrderInstructionsSection,
  IPostOrderInstructionsSectionData,
  IPostOrderInstruction,
  IPostOrderInstructionData,
  IPostOrderInstructionsFieldConstraint,
  IPostOrderInstructionsFieldConstraintCondition,
  IPostOrderInstructionsFieldConstraintData,
  IPostOrderInstructionsFieldConstraintConditionData,
  IPostOrderData,
  IPostOrderInstructionsSubmissionObservationData,
  IPostOrderInstructionFieldOptionSubmission,
  IPostOrderInstructionFieldOptionSubmissionData,
  IPostOrderInstructionFieldFileSubmission,
  IPostOrderInstructionFieldFileSubmissionData,
  IPostOrderInstructionFieldSubmission,
  IPostOrderInstructionFieldSubmissionData,
  IPostOrderInstructionSectionSubmission,
  IPostOrderInstructionSectionSubmissionData,
  IPostOrderInstructionSubmission,
  IPostOrderInstructionSubmissionData,
  IPostOrderSubmission,
  IPostOrderSubmissionData
} from './post-order.types'
import type {
  IFormFieldOptionSubmission,
  IFormFieldSubmission,
  IFormSubmission
} from '../form/form-submission/form-submission.types'
import {
  FormFieldOptionSubmission,
  FormFieldSubmission,
  FormSubmission
} from '../form/form-submission/form-submission'
import { Shift, type IShift } from '../shift'

/**
 * A class representing a PostOrder.
 */
export class PostOrder extends AbstractModel implements IPostOrder {
  readonly id?: number
  readonly client?: IClient | number
  readonly site: ISite | number

  readonly created?: Date
  readonly modified?: Date

  constructor(data: IPostOrderData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderData)[] = ['site']
    this.validate(data, requiredFields)

    this.site = typeof data.site == 'number' ? data.site : new Site(data.site!)

    if (data.id) {
      this.id = data.id
    }

    if (data.client) {
      this.client = typeof data.client == 'number' ? data.client : new Client(data.client!)
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }
  }

  toString() {
    return this.site.toString()
  }

  get postOrderId() {
    return `PO-${(this.site as ISite).code}-${format(this.created!, 'yyyy-MM-dd')}-${padStart(this.id!.toString(), 4, '0')}`
  }
}

/**
 * A class representing a PostOrderInstructionsField.
 */
export class PostOrderInstructionsField
  extends AbstractModel
  implements IPostOrderInstructionsField
{
  readonly id?: number
  readonly field: IFormField
  readonly subtype: number

  readonly constraints: IPostOrderInstructionsFieldConstraint[] = []
  readonly options: IPostOrderInstructionsFieldOption[] = []

  constructor(data: IPostOrderInstructionsFieldData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldData)[] = ['field']
    this.validate(data, requiredFields)

    this.field = new FormField(data.field!)

    if (data.id) {
      this.id = data.id
    }

    this.subtype = data.subtype ?? FormFieldSubTypeEnum.VehicleLog

    if (data.constraints) {
      this.constraints = data.constraints.map(
        (section) => new PostOrderInstructionsFieldConstraint(section)
      )
    }

    if (data.options) {
      this.options = data.options.map((section) => new PostOrderInstructionsFieldOption(section))
    }
  }

  toString() {
    return this.field.toString()
  }
}

/**
 * A class representing a PostOrderInstructionsFieldConstraintCondition.
 */
export class PostOrderInstructionsFieldConstraintCondition
  extends AbstractModel
  implements IPostOrderInstructionsFieldConstraintCondition
{
  readonly id?: number
  readonly condition: IFormFieldConstraintCondition

  constructor(data: IPostOrderInstructionsFieldConstraintConditionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldConstraintConditionData)[] = [
      'condition'
    ]
    this.validate(data, requiredFields)

    this.condition = new FormFieldConstraintCondition(data.condition!)

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return ''
  }
}

/**
 * A class representing a PostOrderInstructionsFieldOption.
 */
export class PostOrderInstructionsFieldConstraint
  extends AbstractModel
  implements IPostOrderInstructionsFieldConstraint
{
  readonly id?: number
  readonly constraint: IFormFieldConstraint
  readonly conditions: IPostOrderInstructionsFieldConstraintCondition[] = []

  constructor(data: IPostOrderInstructionsFieldConstraintData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldConstraintData)[] = ['constraint']
    this.validate(data, requiredFields)

    this.constraint = new FormFieldConstraint(data.constraint!)

    if (data.conditions) {
      this.conditions = data.conditions.map(
        (section) => new PostOrderInstructionsFieldConstraintCondition(section)
      )
    }

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return ''
  }
}

/**
 * A class representing a PostOrderInstructionsFieldOption.
 */
export class PostOrderInstructionsFieldOption
  extends AbstractModel
  implements IPostOrderInstructionsFieldOption
{
  readonly id?: number
  readonly option: IFormFieldOption
  readonly observation_text: string = ''
  readonly incident_level: ReportActivityStatusEnum = ReportActivityStatusEnum.SecurityLevel1

  constructor(data: IPostOrderInstructionsFieldOptionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldOptionData)[] = ['option']
    this.validate(data, requiredFields)

    this.option = new FormFieldOption(data.option!)

    if (data.observation_text) {
      this.observation_text = data.observation_text
    }

    if (data.incident_level) {
      this.incident_level = data.incident_level
    }

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return this.option.value
  }
}

/**
 * A class representing a PostOrderInstructionsSection.
 */
export class PostOrderInstructionsSection
  extends AbstractModel
  implements IPostOrderInstructionsSection
{
  readonly id?: number
  readonly section: IFormSection
  readonly type: PostOrderInstructionSectionTypeEnum
  readonly photo: string | File | null = null
  readonly internal: boolean = false
  readonly fields: IPostOrderInstructionsField[] = []

  constructor(data: IPostOrderInstructionsSectionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsSectionData)[] = ['section']
    this.validate(data, requiredFields)

    this.section = new FormSection(data.section!)

    this.type = data.type ?? PostOrderInstructionSectionTypeEnum.Checkpoint

    if (data.id) {
      this.id = data.id
    }

    if (data.internal) {
      this.internal = data.internal
    }

    if (data.fields) {
      this.fields = data.fields.map((section) => new PostOrderInstructionsField(section))
    }

    if (data.photo) {
      this.photo = data.photo
    }
  }

  toString() {
    return this.section.toString()
  }
}

/**
 * A class representing a PostOrderInstruction.
 */
export class PostOrderInstruction extends AbstractModel implements IPostOrderInstruction {
  readonly id?: number
  readonly template: IForm

  readonly state: PostOrderInstructionStateEnum
  readonly type: PostOrderInstructionTypeEnum
  readonly sections: IPostOrderInstructionsSection[] = []

  constructor(data: IPostOrderInstructionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionData)[] = ['template', 'type']
    this.validate(data, requiredFields)

    this.template = new Form(data.template!)

    this.type = data.type!

    this.state = data.state ?? PostOrderInstructionStateEnum.Draft

    if (data.id) {
      this.id = data.id
    }

    if (data.sections) {
      this.sections = data.sections.map((section) => new PostOrderInstructionsSection(section))
    }
  }

  toString() {
    return ''
  }

  isPublished() {
    return this.state == PostOrderInstructionStateEnum.Published
  }
}

/** #### SUBMISSION #### */

export class PostOrderInstructionsSubmissionObservation
  extends AbstractModel
  implements IPostOrderInstructionsSubmissionObservationData
{
  readonly id?: number

  readonly text: string
  readonly file: string
  readonly location: string

  readonly incident_level: ReportActivityStatusEnum = ReportActivityStatusEnum.SecurityLevel1
  readonly incident_report: number | null = null

  constructor(data: IPostOrderInstructionsSubmissionObservationData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsSubmissionObservationData)[] = [
      'text',
      'file',
      'location'
    ]
    this.validate(data, requiredFields)

    if (data.id) {
      this.id = data.id
    }

    this.text = data.text!
    this.file = data.file!
    this.location = data.location!

    if (data.incident_level) {
      this.incident_level = data.incident_level
    }

    if (data.incident_report) {
      this.incident_report = data.incident_report
    }
  }
}

export class PostOrderInstructionFieldOptionSubmission
  extends AbstractModel
  implements IPostOrderInstructionFieldOptionSubmission
{
  readonly id?: number

  readonly option_submission: IFormFieldOptionSubmission
  readonly incident_report: IIncidentReport | null = null

  constructor(data: IPostOrderInstructionFieldOptionSubmissionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionFieldOptionSubmissionData)[] = [
      'option_submission'
    ]
    this.validate(data, requiredFields)

    if (data.id) {
      this.id = data.id
    }

    this.option_submission = new FormFieldOptionSubmission(data.option_submission!)

    if (data.incident_report) {
      this.incident_report = new IncidentReport(data.incident_report)
    }
  }
}

export class PostOrderInstructionFieldFileSubmission
  extends AbstractModel
  implements IPostOrderInstructionFieldFileSubmission
{
  readonly id?: number
  readonly file: string

  constructor(data: IPostOrderInstructionFieldFileSubmissionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionFieldFileSubmissionData)[] = ['file']
    this.validate(data, requiredFields)

    this.file = data.file!

    if (data.id) {
      this.id = data.id
    }
  }
}
/**
 * A class representing a PostOrderInstructionFieldSubmission.
 */
export class PostOrderInstructionFieldSubmission
  extends AbstractModel
  implements IPostOrderInstructionFieldSubmission
{
  readonly id?: number

  readonly field_submission: IFormFieldSubmission
  readonly file_submission: IPostOrderInstructionFieldFileSubmission | null = null
  readonly option_submissions: IPostOrderInstructionFieldOptionSubmission[] = []

  constructor(data: IPostOrderInstructionFieldSubmissionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionFieldSubmissionData)[] = ['field_submission']
    this.validate(data, requiredFields)

    this.field_submission = new FormFieldSubmission(data.field_submission!)

    if (data.id) {
      this.id = data.id
    }

    if (data.file_submission) {
      this.file_submission = new PostOrderInstructionFieldFileSubmission(data.file_submission)
    }

    if (data.option_submissions) {
      this.option_submissions = data.option_submissions.map(
        (optionSubmission) => new PostOrderInstructionFieldOptionSubmission(optionSubmission)
      )
    }
  }

  toString() {
    return `Post Order Section Submission: ${this.field_submission.toString()}`
  }
}

/**
 * A class representing a PostOrderInstructionSectionSubmission.
 */
export class PostOrderInstructionSectionSubmission
  extends AbstractModel
  implements IPostOrderInstructionSectionSubmission
{
  readonly id?: number

  readonly state: PostOrderInstructionsSectionSubmissionStateEnum
  readonly field_submissions: IPostOrderInstructionFieldSubmission[] = []
  readonly section: IFormSection
  readonly section_type: PostOrderInstructionSectionTypeEnum =
    PostOrderInstructionSectionTypeEnum.Checkpoint

  constructor(data: IPostOrderInstructionSectionSubmissionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionSectionSubmissionData)[] = ['section']
    this.validate(data, requiredFields)

    this.section = new FormSection(data.section!)
    this.state = data.state ?? PostOrderInstructionsSectionSubmissionStateEnum.Todo

    if (data.section_type) {
      this.section_type = data.section_type
    }

    if (data.id) {
      this.id = data.id
    }

    if (data.field_submissions) {
      this.field_submissions = data.field_submissions.map(
        (submission) => new PostOrderInstructionFieldSubmission(submission)
      )
    }
  }

  toString() {
    return `Post Order Section Submission: ${this.section}`
  }
}

/**
 * A class representing a PostOrderInstructionSubmission.
 */
export class PostOrderInstructionSubmission
  extends AbstractModel
  implements IPostOrderInstructionSubmission
{
  readonly id?: number

  readonly submission: IFormSubmission
  readonly read_acknowledgement: boolean = false
  readonly section_submissions: IPostOrderInstructionSectionSubmission[] = []

  constructor(data: IPostOrderInstructionSubmissionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionSubmissionData)[] = ['submission']
    this.validate(data, requiredFields)

    this.submission = new FormSubmission(data.submission!)

    if (data.id) {
      this.id = data.id
    }

    if (data.read_acknowledgement) {
      this.read_acknowledgement = data.read_acknowledgement
    }

    if (data.section_submissions) {
      this.section_submissions = data.section_submissions.map(
        (submission) => new PostOrderInstructionSectionSubmission(submission)
      )
    }
  }

  toString() {
    return `${this.submission.toString()}`
  }
}

/**
 * A class representing a PostOrderSubmission.
 */
export class PostOrderSubmission extends AbstractModel implements IPostOrderSubmission {
  readonly id?: number
  readonly post_order?: IPostOrder

  readonly shift: IShift
  readonly memo: string = ''
  readonly instructions_submissions: IPostOrderInstructionSubmission[] = []

  readonly created?: Date
  readonly modified?: Date

  constructor(data: IPostOrderSubmissionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderSubmissionData)[] = ['shift']
    this.validate(data, requiredFields)

    this.shift = new Shift(data.shift!)

    if (data.id) {
      this.id = data.id
    }

    if (data.post_order) {
      this.post_order = new PostOrder(data.post_order)
    }

    if (data.memo) {
      this.memo = data.memo
    }

    if (data.instructions_submissions) {
      this.instructions_submissions = data.instructions_submissions.map(
        (instructionSubmission) => new PostOrderInstructionSubmission(instructionSubmission)
      )
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }
  }

  toString() {
    return `Post Order Submission: ${this.shift}`
  }

  get submissionId() {
    return `PO-${this.shift.site.code}-${format(this.created!, 'yyyy-MM-dd')}-${padStart(this.id!.toString(), 4, '0')}`
  }
}
