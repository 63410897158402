<template>
  <BasePage title="Post Orders" subtitle="View post order forms">
    <template #page-header:actions>
      <v-btn
        color="primary"
        depressed
        v-if="currentUser?.hasPermission('post_orders.add_postorder')"
        @click="openPostOrderFormDialog()"
      >
        <template #prepend>
          <v-icon start class="hidden-sm-and-down me-0"> {{ route.meta.icon }} </v-icon>
        </template>
        Create Post Order
      </v-btn>
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat height="calc(100vh - 286px)" class="px-4 rounded-lg">
      <v-toolbar flat height="84" color="transparent">
        <PostOrderSearchBox
          v-model:search="currentSearch"
          @update:search="
            (newSearch: string) =>
              debounceFilterSubmissions({ ...filters, page: 1, search: newSearch })
          "
          :filters="filters"
          @filter-applied="(newFilter) => updateFilters(filters, newFilter)"
        />
      </v-toolbar>

      <PostOrderList
        :loading="isLoading"
        :post-orders="postOrders ?? []"
        :search="currentSearch"
        @post-order-selected="routeToPostOrderDetail"
        @post-order-create-pressed="openPostOrderFormDialog"
        @post-order-delete-pressed="openDeletePostOrderDialog"
      />

      <ConfirmationDialog
        width="500"
        title="Delete post order"
        v-model="deleteDialog"
        v-model:error="deleteError"
      >
        <template #message>
          Are you sure you want to delete
          <span class="text-medium-high-emphasis font-weight-bold">{{ selectedPostOrder }}</span
          >?
          <br />
          <div class="pt-2">This action cannot be undone.</div>
        </template>

        <template #actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            variant="flat"
            :disabled="deleteInProgress"
            :loading="deleteInProgress"
            @click="removePostOrder(selectedPostOrder!)"
          >
            Delete
          </v-btn>
        </template>
      </ConfirmationDialog>
    </v-card>
  </BasePage>

  <PostOrderFormDialog
    v-model:dialog="isPostOrderFormDialogActive"
    :post-order="selectedPostOrder!"
    :isEdit="false"
    ref="postOrderFormDialogRef"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { debounce, merge } from 'lodash'
import { reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { DebounceDelay } from '@/utils/constants'

import { useAuthStore } from '@/stores'
import { useQueryClient } from '@tanstack/vue-query'
import { useDeletePostOrder, useFetchPostOrders } from '@/composables/post-order'

import { updateFilters } from '@/utils/filters'

import type { IPostOrderFilterParam } from '@/services'
import type { IPostOrder } from '@/models/post-order'
import type { IClient, ISite } from '@/models/client'

import BasePage from '@/components/base/BasePage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import PostOrderList from '@/components/post-order/PostOrderList.vue'
import PostOrderSearchBox from '@/components/post-order/PostOrderSearchBox.vue'
import PostOrderFormDialog from '@/components/post-order/PostOrderFormDialog.vue'

defineOptions({
  name: 'PostOrderListView'
})

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const route = useRoute()
const router = useRouter()

// set default filters from route query param
// only use valid filter fields from routes query params including `search` and `page` and other valid fields
const filters = reactive<IPostOrderFilterParam>({
  search: route.query.search as string,
  client: route.query.client ? Number(route.query.client) : undefined,
  site: route.query.site ? Number(route.query.site) : undefined
})

//We use a separate ref for the search model in order to not update the queryKey once the search is updated
const currentSearch = ref<string>(filters.search ?? '')

watch(
  filters,
  (value) => {
    /* this will update the URL query based on param values. */
    router
      .replace({
        name: route.name!,
        query: value as Record<string, any>
      })
      .catch((e: Error) => e) //catch navigation duplication error
  },
  {
    immediate: true
  }
)

const { postOrders, isLoading, error, queryKey } = useFetchPostOrders(filters)

const queryPostOrder = useQueryClient()

// debouncing
const debounceFilterSubmissions = debounce(function (filterParams: IPostOrderFilterParam) {
  // mutates filters
  merge(filters, filterParams)
  // TODO: Check why are we invalidating instead of just updating the query key
  queryPostOrder.invalidateQueries({ queryKey: queryKey.value })
}, DebounceDelay.Long)

function clearErrors() {
  error.value = null
}

// UTILS
function routeToPostOrderDetail(postOrder: IPostOrder) {
  const clientId = (postOrder.client as IClient).id
  const siteId = (postOrder.site as ISite).code

  return router.push({
    name: 'post-order-instruction-detail',
    params: { clientId: clientId, siteId: siteId, id: postOrder.id }
  })
}

const {
  isPending: deleteInProgress,
  error: deleteError,
  mutate: deletePostOrder,
  reset: clearDeleteErrors
} = useDeletePostOrder()

const deleteDialog = ref(false)

const selectedPostOrder = ref<IPostOrder>()

function openDeletePostOrderDialog(postOrder: IPostOrder) {
  clearDeleteErrors()
  deleteDialog.value = true
  selectedPostOrder.value = postOrder
}

function removePostOrder(postOrder: IPostOrder) {
  deletePostOrder(postOrder.id!, {
    onSuccess: () => {
      deleteDialog.value = false
    }
  })
}

const isPostOrderFormDialogActive = ref(false)

function openPostOrderFormDialog(postOrder?: IPostOrder) {
  selectedPostOrder.value = postOrder

  isPostOrderFormDialogActive.value = true
}
</script>
