import { AbstractModel } from '@/models/base'
import type {
  IVehicle,
  IVehicleData,
  IVehicleLog,
  IVehicleLogAttachment,
  IVehicleLogAttachmentData,
  IVehicleLogData,
  IVehicleMake,
  IVehicleMakeData,
  IVehicleModel,
  IVehicleModelData
} from './vehicle.types'
import { Shift, type IShift } from '@/models/shift'

export class VehicleMake extends AbstractModel implements IVehicleMake {
  id?: number
  name: string

  constructor(data: IVehicleMakeData) {
    super()

    const requiredFields: (keyof IVehicleMakeData)[] = ['name']

    this.validate(data, requiredFields)

    this.name = data.name!

    if (data.id) {
      this.id = data.id
    }
  }

  toString(): string {
    return this.name
  }
}

export class VehicleModel extends AbstractModel implements IVehicleModel {
  id?: number
  name: string
  make: number | IVehicleMake

  constructor(data: IVehicleModelData) {
    super()

    const requiredFields: (keyof IVehicleModelData)[] = ['name', 'make']

    this.validate(data, requiredFields)

    this.name = data.name!
    this.make = typeof data.make === 'number' ? data.make! : new VehicleMake(data.make!)

    if (data.id) {
      this.id = data.id
    }
  }

  toString(): string {
    return this.name
  }
}

export class Vehicle extends AbstractModel implements IVehicle {
  id?: number
  plate: string
  year: number
  model: number | IVehicleModel

  constructor(data: IVehicleData) {
    super()

    const requiredFields: (keyof IVehicleData)[] = ['plate', 'year', 'model']

    this.validate(data, requiredFields)

    this.plate = data.plate!
    this.year = data.year!
    this.model = typeof data.model == 'number' ? data.model : new VehicleModel(data.model!)

    if (data.id) {
      this.id = data.id
    }
  }

  toString(): string {
    throw new Error('Method not implemented.')
  }
}

export class VehicleLog extends AbstractModel implements IVehicleLog {
  id?: number

  vehicle: number | IVehicle
  mileage: number
  fuel_percentage: number
  notes: string = ''
  shift: IShift | number

  owner?: string
  created?: Date
  modified?: Date

  constructor(data: IVehicleLogData) {
    super()

    const requiredFields: (keyof IVehicleLogData)[] = [
      'vehicle',
      'shift',
      'mileage',
      'fuel_percentage'
    ]

    this.validate(data, requiredFields)

    this.vehicle = typeof data.vehicle === 'number' ? data.vehicle! : new Vehicle(data.vehicle!)
    this.shift = typeof data.shift === 'number' ? data.shift! : new Shift(data.shift!)
    this.mileage = data.mileage!
    this.fuel_percentage = data.fuel_percentage!

    if (data.id) {
      this.id = data.id
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }

    if (data.notes) {
      this.notes = data.notes
    }

    if (data.owner) {
      this.owner = data.owner
    }
  }

  toString(): string {
    throw new Error('Method not implemented.')
  }
}

export class VehicleLogAttachment extends AbstractModel implements IVehicleLogAttachment {
  id?: number
  file: string | File
  description: string = ''

  constructor(data: IVehicleLogAttachmentData) {
    super()

    const requiredFields: (keyof IVehicleLogAttachmentData)[] = ['file']

    this.validate(data, requiredFields)

    this.file = data.file!
    if (data.id) {
      this.id = data.id
    }

    if (data.description) {
      this.description = data.description
    }
  }

  toString(): string {
    throw new Error('Method not implemented.')
  }
}
