<template>
  <SearchBox
    label="Search"
    prepend-inner-icon="mdi-magnify"
    hide-details="auto"
    variant="outlined"
    density="comfortable"
    style="max-width: 400px"
    v-model="search"
    @filter-applied="applyFilter()"
    :filters-applied="filtersApplied"
  >
    <template #filters v-if="localFilters">
      <div class="filter-line-item" v-if="currentUser?.hasPermission('clients.view_client')">
        <label>Clients</label>
        <div class="filter-line-item-value">
          <ClientAutoCompletePicker
            clearable
            :readonly="localFilters.client != null"
            v-model="localFilters.client"
            @update:model-value="setSiteFilterValue()"
            label=""
          />
        </div>
      </div>
      <div class="filter-line-item" v-if="currentUser?.hasPermission('clients.view_site')">
        <label>Site</label>
        <div class="filter-line-item-value">
          <SiteAutoCompletePicker
            clearable
            :readonly="localFilters.site != null"
            v-model="localFilters.site"
            :disabled="!localFilters.client"
            :client="localFilters.client"
            label=""
          />
        </div>
      </div>

      <!-- <div class="filter-line-item" v-if="currentUser?.hasPermission('employees.view_employee')">
        <label>Reporter</label>
        <div class="filter-line-item-value">
          <EmployeeAutoCompletePicker
            clearable
            :readonly="localFilters.guard != null"
            v-model="localFilters.guard"
            label=""
          />
        </div>
      </div> -->

      <div class="filter-line-item" v-if="currentUser?.hasPermission('shifts.view_shift')">
        <label>Shift</label>
        <div class="filter-line-item-value">
          <ShiftAutoCompletePicker
            clearable
            :readonly="localFilters.shift != null"
            v-model="localFilters.shift"
            label=""
          />
        </div>
      </div>
    </template>
  </SearchBox>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import type { IPostOrderSubmissionFilterParam } from '@/services'
import { useAuthStore } from '@/stores/auth'
import { cloneDeep, omit } from 'lodash'

import SiteAutoCompletePicker from '@/components/sites/SiteAutoCompletePicker.vue'
import ClientAutoCompletePicker from '@/components/clients/ClientAutoCompletePicker.vue'
import SearchBox from '@/components/common/SearchBox.vue'
import ShiftAutoCompletePicker from '../shifts/ShiftAutoCompletePicker.vue'

defineOptions({
  name: 'PostOrderSearchBox'
})

const search = defineModel<string>('search')

interface Props {
  filters: IPostOrderSubmissionFilterParam
}
interface Emits {
  (e: 'filter-applied', filter: IPostOrderSubmissionFilterParam): void
}
const emit = defineEmits<Emits>()
const props = defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const localFilters = ref<IPostOrderSubmissionFilterParam>({})

const filtersApplied = computed(() => omit(props.filters, ['page', 'search']))

watch(
  () => props.filters,
  (value) => {
    // do not assign reference value to localFilter
    localFilters.value = cloneDeep(value)
  },
  {
    immediate: true,
    deep: true
  }
)

function setSiteFilterValue() {
  if (!localFilters.value.client) {
    localFilters.value.site = null
  }
}

function applyFilter() {
  const updatedFilter = props.filters
  for (const filter in props.filters) {
    const filterKey = filter as keyof IPostOrderSubmissionFilterParam

    const updatedValue = localFilters.value[filterKey]
    // filter fields that are not null and that match the filter object
    if (updatedValue !== null) {
      updatedFilter[filterKey] = updatedValue as any
    } else {
      // reset other fields that are not set
      updatedFilter[filterKey] = undefined
    }
  }
  emit('filter-applied', updatedFilter)
}
</script>
