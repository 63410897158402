import { computed, ref, toRef, type MaybeRef } from 'vue'

import type { IShiftFilterParam } from '@/services'
import { Shift, type IShiftData } from '@/models/shift'

import shiftService from '@/services/shift/shift'

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

export function useFetchShifts(filter?: IShiftFilterParam) {
  const queryKey = ref(['shifts', filter])

  const count = ref<number>(0)

  const {
    isLoading,
    isError,
    error,
    data: shifts
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => shiftService.fetchShifts(filter),

    select: (data) => {
      count.value = data.count
      return data.results.map((shift) => new Shift(shift))
    }
  })

  return { queryKey, shifts, count, error, isError, isLoading }
}

export function useFetchShift(id: MaybeRef<number>) {
  const shiftIdRef = toRef(id)

  const queryKey = ref(['shift', shiftIdRef])

  const {
    data: shift,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(shiftIdRef.value)),
    queryFn: () => shiftService.fetchShift(shiftIdRef.value),
    select: (data) => new Shift(data)
  })

  return { shift, isPending, error, queryKey }
}

export function useCreateShift() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-shift'],
    mutationFn: (shift: IShiftData) => shiftService.createShift(shift),

    onSuccess: (data) => {
      queryClient.setQueryData(['shift', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['shifts']) })
      snackbarStore.showSnackbar('Shift created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateShift() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-shift'],
    mutationFn: (shift: IShiftData) => shiftService.updateShift(shift),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['shift', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['shifts']) })
      snackbarStore.showSnackbar('Shift updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteShift() {
  const mutationKey = ref(['delete-shift'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => shiftService.deleteShift(id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['shifts']) })
      snackbarStore.showSnackbar('Shift deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}
