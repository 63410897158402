<template>
  <v-data-table-server
    v-model:page="page"
    :headers="submissionHeaders"
    :items="submissions"
    :loading="props.loading"
    density="comfortable"
    class="data-table"
    :items-per-page-options="[{ value: 25, title: '25' }]"
    :items-per-page="25"
    :items-length="count"
    no-filter
    fixed-header
    height="calc(100% - 37px)"
    style="height: calc(100% - 93px); width: 100%"
    :row-props="generateRowProps"
    :hover="submissions ? submissions.length > 0 : false"
  >
    <template v-slot:[`item.submissionId`]="{ value }">
      <div class="text-body-2 font-weight-medium text-link">{{ value }}</div>
    </template>

    <template v-slot:[`item.index`]="{ index }">{{ index + 1 }}</template>

    <template v-slot:no-data>
      <NoDataAvailablePlaceholder
        :header="
          props.search && !props.loading
            ? `No submissions found matching &quot;${truncate(props.search, { length: 20 })}&quot;`
            : 'No submissions available'
        "
        header-class="font-weight-medium text-h5"
      >
        <template #prepend>
          <v-card
            variant="flat"
            class="mask-bottom-card mb-3"
            color="transparent"
            width="180"
            height="120"
          >
            <v-list
              style="color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity))"
              variant="outlined"
              density="compact"
              lines="two"
              class="pt-0"
            >
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="green-darken-2 text-white">
                    JD
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="amber-darken-2 text-white">
                    DM
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
            </v-list>
          </v-card>
        </template>

        <template #description>
          <p class="mt-0 mb-6 text-caption text-medium-emphasis">
            <span>
              A <span class="text-primary">Post Order Submission</span> will be created from the Tag
              mobile app and can be managed here</span
            >
          </p>
        </template>
      </NoDataAvailablePlaceholder>
    </template>
  </v-data-table-server>
</template>
<script setup lang="ts">
import format from 'date-fns/format'
import { truncate } from 'lodash'

import type { IPostOrderSubmission } from '@/models/post-order'

import type { ReadonlyTableHeaders } from '@/utils/tables'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'

interface Props {
  submissions: IPostOrderSubmission[]
  count: number
  loading: boolean
  search: string
}

interface Emit {
  (e: 'submission-selected', submission: IPostOrderSubmission): void
}

const page = defineModel<number>('page')

const emit = defineEmits<Emit>()
const props = defineProps<Props>()

function generateRowProps(props: { index: number; item: IPostOrderSubmission }) {
  return {
    // apply click listener to row props to open detail drawer
    onClick: () => emit('submission-selected', props.item)
  }
}

const submissionHeaders: ReadonlyTableHeaders = [
  {
    title: 'ID',
    key: 'submissionId'
  },

  {
    title: 'Client',
    key: 'shift.client'
  },

  {
    title: 'Site',
    key: 'shift.site'
  },

  {
    title: 'Shift Start',
    key: 'shift_start',
    value(item: IPostOrderSubmission) {
      return format(item.shift.start, 'MMMM dd, yyyy - HH:mm')
    }
  },

  {
    title: 'Shift End',
    key: 'shift_end',
    value(item: IPostOrderSubmission) {
      return format(item.shift.end, 'MMMM dd, yyyy - HH:mm')
    }
  },

  {
    title: 'Reporter',
    key: 'shift.guards',
    value(item: IPostOrderSubmission) {
      return item.shift.guards.toString()
    }
  },

  {
    title: 'Last Updated',
    key: 'modified',
    value(item: IPostOrderSubmission) {
      return format(item.modified!, 'MMMM dd, yyyy - HH:mm')
    }
  }
]
</script>
