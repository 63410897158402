<template>
  <ErrorAlert
    v-if="error != null"
    dense
    :error="error"
    @clearErrors="clearErrors"
    class="mb-0 mt-4 rounded"
  />
  <v-card
    v-if="!isFullscreen"
    variant="flat"
    tile
    :min-height="isInstructionDescriptionExpanded ? 150 : 48"
    :max-height="isInstructionDescriptionExpanded ? '' : 48"
    color="background"
    class="d-flex flex-row border border-b-0 rounded-t-lg rounded-b-0"
    style="transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1)"
  >
    <v-card-text
      class="d-flex justify-start align-start mask-bottom-card-small text-medium-emphasis pt-0 pb-4 pe-4 ps-5 mt-3 mr-6"
      overflow-y-auto
      :class="`overflow-y-${isInstructionDescriptionExpanded ? 'auto' : 'hidden'}`"
    >
      {{ instruction.template.description }}
    </v-card-text>

    <v-tooltip
      :text="`${isInstructionDescriptionExpanded ? 'Shrink' : 'Expand'} description preview`"
    >
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          @click="toggleInstructionDescriptionExpansion()"
          color="medium-emphasis"
          variant="text"
          class="rounded-circle mr-5 mt-3"
          density="comfortable"
          size="x-small"
          :icon="isInstructionDescriptionExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up'"
        ></v-btn>
      </template>
    </v-tooltip>
  </v-card>

  <v-layout full-height class="flex-fill" style="height: calc(-60px + 100vh)">
    <PostOrderInstructionDetailSectionList
      :post-order-id="postOrderId"
      :post-order-instruction-id="postOrderInstructionId!"
      :sections="instruction.sections"
      :selected-section="selectedSection?.id"
      @edit-section-pressed="openPostOrderInstructionSectionFormDialog"
      @select-section-pressed="(section) => selectSection(section.id!)"
      @create-post-order-instruction-section-pressed="openPostOrderInstructionSectionFormDialog()"
    />

    <PostOrderInstructionDetailSectionFormDialog
      v-model:dialog="isPostOrderInstructionSectionFormDialogActive"
      :post-order-instruction-section="formSelectedSectionData"
      :isEdit="isEditingPostOrderInstructionSection"
      @saved-section="(section) => selectSection(section.id!)"
    />

    <v-main
      flat
      id="sections-container"
      class="rounded-lg bg-background border-t border-b"
      style="overflow-y: scroll"
    >
      <v-card
        class="d-flex flex-column"
        tile
        variant="flat"
        height="100%"
        :class="isFieldDetailDrawerActive ? 'mr-n4' : ''"
      >
        <NoDataAvailablePlaceholder
          class="pt-6"
          v-if="instruction.sections.length == 0"
          width="400"
          header="No checkpoints added"
          header-class="font-weight-medium text-h5"
        >
          <template #description>
            <p class="mt-0 mb-6 text-caption text-medium-emphasis">
              <span> You do not have any sections for this post order instruction.</span>
            </p>
          </template>

          <template #primaryAction>
            <v-btn color="primary" depressed @click="openPostOrderInstructionSectionFormDialog()">
              <template #prepend>
                <v-icon left class="hidden-sm-and-down"> mdi-plus </v-icon>
              </template>
              Create Checkpoint
            </v-btn>
          </template>
        </NoDataAvailablePlaceholder>

        <template v-else-if="selectedSection">
          <div>
            <v-toolbar density="compact" color="transparent" class="border-b">
              <template #title>
                <span class="text-subtitle-1 font-weight-medium">{{
                  selectedSection.section.title
                }}</span>
              </template>

              <template #append>
                <Transition :name="transition">
                  <div v-if="sectionHasPhotoOrDescription">
                    <v-tooltip
                      :text="`${isSectionDescriptionPreview ? 'Shrink' : 'Expand'} checkpoint description preview`"
                    >
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          @click="toggleSectionDescriptionPreview()"
                          color="medium-emphasis"
                          variant="text"
                          class="rounded-circle mr-1"
                          density="comfortable"
                          size="x-small"
                          :icon="
                            isSectionDescriptionPreview ? 'mdi-chevron-down' : 'mdi-chevron-up'
                          "
                        ></v-btn>
                      </template>
                    </v-tooltip>
                  </div>
                </Transition>
              </template>
            </v-toolbar>
            <v-expand-transition>
              <v-card
                v-if="sectionHasPhotoOrDescription && isSectionDescriptionPreview"
                variant="flat"
                color="#fafafa"
                tile
                class="rounded-0 d-flex flex-row border-b"
                style="transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) !important"
              >
                <v-card-text
                  class="d-flex justify-start align-start mask-bottom-card-small text-medium-emphasis pt-3 pb-5 pe-8 ps-5"
                >
                  {{ selectedSection.section.description }}
                </v-card-text>

                <ImageViewer
                  v-if="selectedSection.photo"
                  :src="selectedSection.photo as string"
                  class="align-end pa-3"
                  height="100%"
                  :width="184"
                  contain
                />
              </v-card>
            </v-expand-transition>
          </div>
          <PostOrderInstructionDetailSectionFieldCanvas
            ref="sectionCanvasRef"
            :is-section-vehicle-log="
              selectedSection.type == PostOrderInstructionSectionTypeEnum.VehicleLog
            "
          />
        </template>

        <template v-else-if="!selectedSection">
          <NoDataAvailablePlaceholder
            class="pt-16"
            width="400"
            header="No section selected"
            header-class="font-weight-medium text-h5"
          >
            <template #description>
              <p class="mt-0 mb-6 text-caption text-medium-emphasis">
                <span> Select a section from the left panel to view fields workflow </span>
              </p>
            </template>
          </NoDataAvailablePlaceholder>
        </template>
      </v-card>
    </v-main>

    <PostOrderInstructionDetailFieldDrawer
      v-if="selectedNode"
      v-model="isFieldDetailDrawerActive"
      :field="selectedNode.field"
    />
  </v-layout>
</template>

<script setup lang="ts">
import { computed, inject, provide, ref, watch } from 'vue'

import type { ISystemError } from '@/models/error'
import type { IPostOrderNode } from '@/models/post-order/tree'
import {
  PostOrderInstructionSectionTypeEnum,
  type IPostOrderInstruction,
  type IPostOrderInstructionsSection,
  type IPostOrderInstructionsSectionData
} from '@/models/post-order'

import {
  PostOrderFullscreenSymbol,
  PostOrderNodeSymbol,
  PostOrderSymbol
} from '@/components/post-order/postOrderProvide'

import ErrorAlert from '../common/ErrorAlert.vue'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import PostOrderInstructionDetailFieldDrawer from '@/components/post-order/PostOrderInstructionDetailFieldDrawer.vue'
import PostOrderInstructionDetailSectionFieldCanvas from '@/components/post-order/PostOrderInstructionDetailSectionFieldCanvas.vue'
import PostOrderInstructionDetailSectionFormDialog from './PostOrderInstructionDetailSectionFormDialog.vue'
import PostOrderInstructionDetailSectionList from '@/components/post-order/PostOrderInstructionDetailSectionList.vue'
import ImageViewer from '../common/ImageViewer.vue'

interface Props {
  instruction: IPostOrderInstruction
  postOrderId: number
}

const props = defineProps<Props>()

const postOrderId = computed(() => props.postOrderId)
const postOrderInstructionId = computed(() => props.instruction.id!)

const selectedSection = ref<IPostOrderInstructionsSection | null>(null)
const postOrderInstructionSectionId = computed(() => selectedSection.value?.id!)

const sectionCanvasRef = ref<typeof PostOrderInstructionDetailSectionFieldCanvas>()

const isInstructionDescriptionExpanded = ref(false)
function toggleInstructionDescriptionExpansion() {
  isInstructionDescriptionExpanded.value = !isInstructionDescriptionExpanded.value
}

provide(PostOrderSymbol, {
  postOrderId: postOrderId,
  instructionId: postOrderInstructionId,
  sectionId: postOrderInstructionSectionId
})

watch(
  () => props.instruction,
  (value) => {
    if (value && value.sections.length > 0) {
      if (selectedSection.value) {
        const isSelectedSectionInNewSectionList = value.sections.find(
          (section) => section.id == selectedSection.value!.id
        )

        if (!isSelectedSectionInNewSectionList) {
          const firstSection = value.sections[0]
          // Select first section if no sections currently selected
          selectSection(firstSection.id!)
        }
      } else {
        // If no section was selected then just select the first section in the list
        const firstSection = value.sections[0]

        if (firstSection) selectSection(firstSection.id!)
      }
    }
  },
  { immediate: true, deep: true }
)

function selectSection(sectionId: number) {
  const foundSection = props.instruction
    ? props.instruction.sections.find((postOrderSection) => postOrderSection.id == sectionId) ??
      null
    : null
  selectedSection.value = foundSection

  // pan to center
  sectionCanvasRef.value?.resetViewPort()
}

watch(selectedSection, () => {
  // Deselect any selected node
  selectNode()
})

// TREE NODE
const selectedNode = ref<IPostOrderNode | null>(null)

function selectNode(node?: IPostOrderNode) {
  selectedNode.value = node ?? null

  if (node) {
    isFieldDetailDrawerActive.value = true
  }
}

provide(PostOrderNodeSymbol, {
  selectedNode,
  selectNode
})

const error = ref<ISystemError | null>(null)

function clearErrors() {
  error.value = null
}

const isFieldDetailDrawerActive = ref(false)

const isPostOrderInstructionSectionFormDialogActive = ref(false)
const formSelectedSectionData = ref<IPostOrderInstructionsSectionData | null>(null)
const isEditingPostOrderInstructionSection = ref(false)

function openPostOrderInstructionSectionFormDialog(section?: IPostOrderInstructionsSection) {
  isEditingPostOrderInstructionSection.value = section ? true : false

  formSelectedSectionData.value = section
    ? section
    : {
        type: PostOrderInstructionSectionTypeEnum.Checkpoint,
        section: { order: props.instruction.sections.length + 1 }
      }

  isPostOrderInstructionSectionFormDialogActive.value = true
}

const isReverseTransition = ref(false)

const transition = computed(() => {
  return !isReverseTransition.value ? 'scroll-y-transition' : 'scroll-y-reverse-transition'
})

watch(selectedSection, (value) => {
  const isReversed = !(value && !!value.photo && !!value.section.description)
  isReverseTransition.value = isReversed
})

const isSectionDescriptionPreview = ref(true)

function toggleSectionDescriptionPreview() {
  isSectionDescriptionPreview.value = !isSectionDescriptionPreview.value
}

// watcher in fullscreen to hide description when true

const postOrderFullscreenContext = inject(PostOrderFullscreenSymbol)
if (!postOrderFullscreenContext)
  throw new Error('[Post Order] Could not find injected post order context')

// Fullscreen
const isFullscreen = computed(() => {
  return !!postOrderFullscreenContext.isFullscreen.value
})

watch(isFullscreen, (value) => {
  if (value) {
    isSectionDescriptionPreview.value = false
  }
})

const sectionHasPhotoOrDescription = computed(
  () => !!(selectedSection.value?.photo || selectedSection.value?.section.description)
)
</script>
