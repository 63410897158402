import type { IShiftData } from '@/models/shift'
import type { IShiftService, IShiftFilterParam } from './shift.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'
import type { IPaginatedListData } from '../types'

/**
 * A class representing an ShiftService API handler.
 */
export class ShiftService implements IShiftService {
  private endpoint: string

  // ShiftService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/shifts/'
  }

  // Method to fetch a list of shifts from the API
  fetchShifts(filter?: IShiftFilterParam): Promise<IPaginatedListData<IShiftData>> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single shift by their ID from the API
  fetchShift(id: number): Promise<IShiftData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new shift on the API
  createShift(data: IShiftData): Promise<IShiftData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing shift on the API
  updateShift(data: IShiftData): Promise<IShiftData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a shift by their ID from the API
  deleteShift(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  /* Method to assign a guard to an existing shift on the API */
  assignShift(shiftId: number, guardId: number): Promise<IShiftData> {
    return this.axios
      .put(`${this.endpoint}${shiftId}/assign-guard`, { guard: guardId })
      .then(({ data }) => data)
  }

  /* Method to unassign a guard from an existing shift on the API */
  unassignShift(shiftId: number, guardId: number): Promise<IShiftData> {
    return this.axios
      .put(`${this.endpoint}${shiftId}/unassign-guard`, { guard: guardId })
      .then(({ data }) => data)
  }
}

// Default export instantiation of the ShiftService
const shiftService = new ShiftService(axios)
export default shiftService
