<template>
  <div class="image-viewer-list">
    <div v-if="isLoading" class="mx-auto">
      <v-progress-circular class="mx-auto" indeterminate color="primary" width="2" size="20" />
    </div>
    <div v-if="isError">
      <ErrorAlert v-if="isError" :error="error!" @clearErrors="error = null" />
    </div>
    <div v-if="attachments" class="image-container">
      <div
        class="detail-item-subtitle text-high-emphasis pb-4"
        style="padding-inline-start: 2px"
        v-for="attachment in attachments"
        :key="attachment.id"
      >
        <div class="d-flex flex-column">
          <div
            class="d-flex flex-row flex-grow-1 align-space-between text-medium-emphasis text-caption font-weight-medium"
            style="line-height: 0.9rem"
          >
            {{ attachment.description }}
          </div>
          <div>
            <ImageViewer :src="attachment.file as string" width="100%" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachments && attachments.length === 0">No attachments found.</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useFetchVehicleLogAttachments } from '@/composables/vehicle-log'

import ImageViewer from '@/components/common/ImageViewer.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'

interface Props {
  vehicleLogId: number
}
const props = defineProps<Props>()
const vehicleLogId = computed(() => props.vehicleLogId!)

const { attachments, isError, error, isLoading } = useFetchVehicleLogAttachments(vehicleLogId)
</script>

<style scoped>
.image-viewer-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 80vh;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
