<template>
  <v-data-table
    v-model:page="page"
    :headers="postOrderHeaders"
    :items="postOrders"
    :loading="props.loading"
    density="comfortable"
    class="data-table"
    :items-per-page-options="[{ value: 25, title: '25' }]"
    :items-per-page="25"
    no-filter
    fixed-header
    height="calc(100% - 37px)"
    style="height: calc(100% - 93px); width: 100%"
    :row-props="generateRowProps"
    :hover="postOrders ? postOrders.length > 0 : false"
  >
    <template v-slot:[`item.postOrderId`]="{ value }">
      <div class="text-body-2 font-weight-medium text-link">{{ value }}</div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <PostOrderDetailOptionsButton
        :post-order="item"
        @post-order-delete-pressed="(postOrder) => emit('post-order-delete-pressed', postOrder)"
      />
    </template>

    <template v-slot:no-data v-if="!loading">
      <NoDataAvailablePlaceholder
        :header="
          props.search && !props.loading
            ? `No post orders found with data matching &quot;${truncate(props.search, { length: 20 })}&quot;`
            : 'No post orders available'
        "
        header-class="font-weight-medium text-h5"
      >
        <template #prepend>
          <v-card
            variant="flat"
            class="mask-bottom-card mb-3"
            color="transparent"
            width="180"
            height="120"
          >
            <v-list
              style="color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity))"
              variant="outlined"
              density="compact"
              lines="two"
              class="pt-0"
            >
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="green-darken-2 text-white">
                    JD
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="amber-darken-2 text-white">
                    DM
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
            </v-list>
          </v-card>
        </template>

        <template #description>
          <p class="mt-0 mb-6 text-caption text-medium-emphasis">
            <span v-if="currentUser?.hasPermission('post_orders.add_postorder')">
              <template v-if="search">
                Oops! No post orders match your current search criteria. Try adjusting your filters
                for better results.
              </template>
              <template v-else>
                It looks like there are currently no post orders in the post order list. Get started
                by adding new post orders here to build your post order list.</template
              >
            </span>
            <span v-else
              >You do not have the permission to create a post order. Contact your administrator for
              more details
            </span>
          </p>
        </template>

        <template #primaryAction>
          <v-btn
            v-if="currentUser?.hasPermission('post_orders.add_postorder')"
            color="primary"
            @click="emit('post-order-create-pressed')"
          >
            <template #prepend>
              <v-icon start class="hidden-sm-and-down me-0"> {{ route.meta.icon }} </v-icon>
            </template>
            Create Post Order
          </v-btn>
        </template>
      </NoDataAvailablePlaceholder>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import { truncate } from 'lodash'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { format } from 'date-fns'

import { useAuthStore } from '@/stores'
import type { IPostOrder } from '@/models/post-order'

import type { ReadonlyTableHeaders } from '@/utils/tables'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import PostOrderDetailOptionsButton from '@/components/post-order/PostOrderDetailOptionsButton.vue'

interface Props {
  postOrders: IPostOrder[]
  loading: boolean
  search: string
}

interface Emit {
  (e: 'post-order-create-pressed'): void
  (e: 'post-order-selected', postOrder: IPostOrder): void
  (e: 'post-order-delete-pressed', postOrder: IPostOrder): void
}

defineOptions({
  name: 'PostOrderList'
})

const page = defineModel<number>('page')

const emit = defineEmits<Emit>()
const props = defineProps<Props>()

const route = useRoute()
const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const postOrderHeaders: ReadonlyTableHeaders = [
  {
    title: 'ID',
    key: 'postOrderId',
    sortable: false
  },
  {
    title: 'Client',
    key: 'client',
    sortable: false
  },

  {
    title: 'Site',
    key: 'site',
    sortable: false
  },

  {
    title: 'Last Updated',
    key: 'modified',
    value(item: IPostOrder) {
      return format(item.modified!, 'MMMM dd, yyyy - HH:mm')
    },
    sortable: false
  },

  {
    title: '',
    key: 'actions',
    align: 'center',
    sortable: false,
    width: '150'
  }
]

function generateRowProps(props: { index: number; item: IPostOrder }) {
  return {
    // apply click listener to row props to open detail drawer
    onClick: () => emit('post-order-selected', props.item)
  }
}
</script>
