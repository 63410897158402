<template>
  <v-autocomplete
    label="Make"
    v-model="vehicle"
    :items="vehicleMakes"
    v-model:search="search"
    item-value="id"
    :item-title="
      (item: IVehicleMake) => {
        return item instanceof VehicleMake ? item.toString() : item
      }
    "
    :loading="loading"
    :error-messages="combinedErrorMessages"
    no-filter
    v-bind="$attrs"
  >
    <template #append-item v-if="!hideCreateBtn">
      <DropdownListItemAction :search="search" @click="addVehicleMake()" label="Create Make" />
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from 'vue'

import { useCreateVehicleMake, useFetchVehicleMakes } from '@/composables/vehicle-make'
import { isArray } from 'lodash'

import {
  VehicleMake,
  type IVehicleMake,
  type IVehicleMakeData
} from '@/models/fleet-management/vehicle'
import type { ISystemError } from '@/models/error'

import DropdownListItemAction from '@/components/common/DropdownListItemAction.vue'

const props = defineProps({
  hideCreateBtn: {
    type: Boolean,
    required: false,
    default: () => false
  },
  errorMessages: {
    type: [String, Array] as PropType<string | string[] | null>,
    default: () => []
  }
})

const vehicle = defineModel<null | number | IVehicleMakeData>({ default: null })
const search = ref('')

const { vehicleMakes, error: fetchError, isLoading: isFetchLoading } = useFetchVehicleMakes()

const localError = ref<ISystemError | null>(null)
const loading = computed(() => isFetchLoading.value || isCreatePending.value)

const {
  isPending: isCreatePending,
  error: createError,
  mutate: createVehicleMake,
  reset: resetCreateVehicleMake
} = useCreateVehicleMake()

function addVehicleMake() {
  resetCreateVehicleMake()

  let vehicleMakeInstance = null
  try {
    vehicleMakeInstance = new VehicleMake({ name: search.value })
  } catch (error: any) {
    localError.value = error
  }

  if (vehicleMakeInstance != null) {
    createVehicleMake(vehicleMakeInstance, {
      onSuccess: (vehicleMake) => {
        vehicle.value = vehicleMake.id!
      }
    })
  }
}

const combinedErrorMessages = computed(() => {
  let error: string[] = []

  if (localError.value) {
    error.push(localError.value.message)
  }
  if (fetchError.value) {
    error.push(fetchError.value.message)
  }
  if (createError.value) {
    error.push(createError.value.message)
  }
  if (isArray(props.errorMessages)) {
    error.push(...props.errorMessages)
  } else if (props.errorMessages != null) {
    error.push(props.errorMessages)
  }
  return error
})
</script>
