<template>
  <BasePage title="Post Orders Submissions" subtitle="View post order form submissions">
    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat height="calc(100vh - 286px)" class="px-4 rounded-lg">
      <v-toolbar flat height="84" color="transparent">
        <PostOrderSubmissionSearchBox
          v-model:search="currentSearch"
          @update:search="
            (newSearch: string) =>
              debounceFilterSubmissions({ ...filters, page: 1, search: newSearch })
          "
          :filters="filters"
          @filter-applied="(newFilter) => updateFilters(filters, newFilter)"
        />
      </v-toolbar>

      <PostOrderSubmissionList
        v-model:page="currentPage"
        :loading="isLoading"
        :submissions="submissions ?? []"
        :search="currentSearch"
        :count="count"
        @submission-selected="routeToPostOrderSubmissionDetail"
      />
    </v-card>
  </BasePage>
</template>
<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query'
import { debounce, merge } from 'lodash'
import { computed, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useFetchPostOrdersSubmissions } from '@/composables/post-order'
import type { IPostOrderSubmissionFilterParam } from '@/services/post_order'
import { DebounceDelay } from '@/utils/constants'
import { updateFilters } from '@/utils/filters'

import BasePage from '@/components/base/BasePage.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import PostOrderSubmissionSearchBox from '@/components/post-order/PostOrderSubmissionSearchBox.vue'
import type { IPostOrderSubmission } from '@/models/post-order'

import PostOrderSubmissionList from '@/components/post-order/PostOrderSubmissionList.vue'

const route = useRoute()
const router = useRouter()

// set default filters from route query param
// only use valid filter fields from routes query params including `search` and `page` and other valid fields
const filters = reactive<IPostOrderSubmissionFilterParam>({
  search: route.query.search as string,
  page: route.query.page ? Number(route.query.page) : 1,
  client: route.query.client ? Number(route.query.client) : undefined,
  site: route.query.site ? Number(route.query.site) : undefined,
  shift: route.query.shift ? Number(route.query.shift) : undefined
})

//We use a separate ref for the search model in order to not update the queryKey once the search is updated
const currentSearch = ref<string>(filters.search ?? '')

const currentPage = computed({
  get() {
    return filters.page!
  },
  set(page: number) {
    filters.page = page
  }
})

watch(
  filters,
  (value) => {
    /* this will update the URL query based on param values. */
    router
      .replace({
        name: route.name!,
        query: value as Record<string, any>
      })
      .catch((e: Error) => e) //catch navigation duplication error
  },
  {
    immediate: true
  }
)

const queryClient = useQueryClient()

const { submissions, count, isLoading, error, queryKey } = useFetchPostOrdersSubmissions(filters)

// debouncing
const debounceFilterSubmissions = debounce(function (
  filterParams: IPostOrderSubmissionFilterParam
) {
  // mutates filters
  merge(filters, filterParams)
  // TODO: Check why are we invalidating instead of just updating the query key
  queryClient.invalidateQueries({ queryKey: queryKey.value })
}, DebounceDelay.Long)

function clearErrors() {
  error.value = null
}

// UTILS
function routeToPostOrderSubmissionDetail(submission: IPostOrderSubmission) {
  return router.push({ name: 'post-orders-submission', params: { id: submission.id } })
}
</script>
