<template>
  <BaseRightDrawer v-model:drawer="drawer" @right-drawer-close="drawer = false">
    <template #loading>
      <v-progress-linear
        class="position-absolute"
        style="top: 0"
        indeterminate
        :active="isPending"
      />
    </template>

    <template #error>
      <ErrorAlert v-if="error != null" :error="error" class="mb-0 mb-4 rounded" />
    </template>

    <template #header-before-close v-if="employee">
      <EmployeeDetailOptionsButton
        class="me-4"
        :employee="employee"
        @employee-edit-pressed="(employee: IEmployee) => emit('employee-edit-pressed', employee)"
        @employee-delete-pressed="
          (employee: IEmployee) => emit('employee-delete-pressed', employee)
        "
        @employee-change-password-pressed="
          (employee: IEmployee) => emit('employee-change-password-pressed', employee)
        "
        @employee-change-sites-pressed="openEmployeeSitesDialog()"
        @employee-set-active-pressed="
          (employee: IEmployee) => emit('employee-set-active-pressed', employee)
        "
      />
    </template>

    <div class="d-flex flex-column flex-grow-1" v-if="employee">
      <v-list-item lines="three" class="position-relative rounded-lg mx-6 mb-2 px-0 pb-1 pt-0">
        <v-list-item-action>
          <v-avatar
            variant="flat"
            color="secondary"
            size="74"
            class="mb-6 px-3 text-h4"
            style="border-radius: 5px"
          >
            <span class="text-white font-weight-medium">{{ employee.getInitials() }}</span>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-title class="text-high-emphasis text-h4 font-weight-bold pt-1">
          {{ employee.getFullName() }}
          <div
            v-if="employee.profile!.title"
            class="text-h6 font-weight-regular text-black mb-n2 pt-1"
            style="padding-inline-start: 2px"
          >
            {{ employee.profile!.title }}
          </div>
          <div class="pt-2 pb-6">
            <EmployeeActiveStatusChip :is-active="employee.is_active!" />
          </div>
        </v-list-item-title>
        <v-list-subheader
          class="text-medium-emphasis text-body-2 font-weight-regular pb-3"
          style="min-height: 22px"
        >
          Employee Information
        </v-list-subheader>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Username
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <span>
                {{ employee.username }}
              </span>
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Phone Number
            </div>
            <a
              class="text-body-2 font-weight-medium text-link text-black"
              :href="parsePhoneNumber(employee.profile!.phone)?.getURI()"
              target="_blank"
              rel="noopener noreferrer"
              v-if="employee.profile!.phone"
            >
              {{ parsePhoneNumber(employee.profile!.phone)?.formatInternational() }}
            </a>
            <div v-else class="text-body-2 font-weight-medium text-black">None</div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Email Address
            </div>
            <a
              class="text-body-2 font-weight-medium text-link text-black"
              :href="`mailto:${employee.email}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ employee.email }}
            </a>
          </div>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Security License
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <span v-if="employee.profile!.security_license">
                {{ employee.profile!.security_license }}
              </span>
              <span v-else class="text-high-emphasis text-body-2 font-weight-medium">None</span>
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Permission Groups
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <ul v-if="employee.groups!.length > 0">
                <li v-for="(group, groupIndex) in employee.groups" :key="groupIndex">
                  {{ group }}
                </li>
              </ul>
              <span v-else class="text-high-emphasis text-body-2 font-weight-medium">None</span>
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="d-flex flex-row align-center text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Sites
              <EmployeeDetailSitesFormDialog
                :employeeId="employeeId"
                v-model:dialog="employeeSitesDialog"
              />
            </div>
            <div class="text-body-2 font-weight-medium text-black mt-1">
              <template v-if="employee.profile.site_assignments.length > 0">
                <template
                  v-for="(assignment, assignmentIndex) in employee.profile.site_assignments"
                  :key="assignmentIndex"
                >
                  <v-card variant="outlined" class="rounded-lg border mb-2" tag="ul">
                    <div class="w-full d-flex flex-row flex-grow-1 align-center pb-2 px-3 pt-1">
                      <div
                        class="text-subtitle-2 d-block address mt-2 text-wrap"
                        v-if="assignment.site.address"
                      >
                        <div class="d-block address__field">
                          {{ assignment.site.address.address1 }}
                        </div>
                        <div class="d-block address__field">
                          {{ assignment.site.address.address2 }}
                        </div>

                        <div class="d-block address__field">
                          <span class="wv-text wv-text--body">
                            {{ assignment.site.address.city }},
                            {{ assignment.site.address.region }}
                          </span>
                        </div>
                        <div class="d-block address__field">
                          <span class="wv-text wv-text--body">
                            <span>
                              {{ assignment.site.address.country }},
                              {{ assignment.site.address.postal_code }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <template v-if="assignment.is_site_approver">
                      <v-divider />
                      <div class="position-relative px-3 pt-1 bg-white">
                        <div
                          class="v-list-item-subtitle pb-2 mt-1 text-medium-emphasis"
                          style="
                            font-size: 0.75rem;
                            font-weight: 400;
                            letter-spacing: 0.0178571429em;
                            line-height: 1rem;
                          "
                        >
                          <div class="d-flex flex-column font-weight-medium">
                            <div>Approver</div>
                            <div class="text-black font-weight-bold">
                              <div class="d-flex flex-row align-center">
                                <v-icon
                                  icon="mdi-account-check"
                                  size="16"
                                  color="success"
                                  class="pr-1"
                                />
                                <div>Yes</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-card>
                </template>
              </template>
              <span v-else class="text-high-emphasis text-body-2 font-weight-medium">None</span>
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Last Login
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              {{
                employee.last_login
                  ? dateAdapter.formatByString(employee.last_login, 'PPP pp')
                  : 'None'
              }}
            </div>
          </div>
        </v-list-item-subtitle>
      </v-list-item>
    </div>
  </BaseRightDrawer>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useDate } from 'vuetify'
import parsePhoneNumber from 'libphonenumber-js'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'
import type { IEmployee } from '@/models/employee'

import { useFetchEmployee } from '@/composables/employee'

import BaseRightDrawer from '@/components/base/BaseRightDrawer.vue'
import EmployeeActiveStatusChip from './EmployeeActiveStatusChip.vue'
import EmployeeDetailOptionsButton from './EmployeeDetailOptionsButton.vue'
import ErrorAlert from '../common/ErrorAlert.vue'
import EmployeeDetailSitesFormDialog from './EmployeeDetailSitesFormDialog.vue'

interface Props {
  employeeId: number
}
const props = defineProps<Props>()

interface Emit {
  (e: 'employee-edit-pressed', employee: IEmployee): void
  (e: 'employee-delete-pressed', employee: IEmployee): void
  (e: 'employee-change-password-pressed', employee: IEmployee): void
  (e: 'employee-set-active-pressed', employee: IEmployee): void
}
const emit = defineEmits<Emit>()

const drawer = defineModel<boolean>('drawer')

const dateAdapter = useDate() as DateFnsUtils

const employeeId = computed(() => props.employeeId!)

const { employee, isPending, error } = useFetchEmployee(employeeId)

const employeeSitesDialog = ref(false)

function openEmployeeSitesDialog() {
  employeeSitesDialog.value = true
}
</script>

<style lang="scss">
.detail-item-subtitle {
  opacity: 1;
}
</style>
