import { AbstractModel } from '@/models/base'
import { format } from 'date-fns'
import type {
  IFormFieldOptionSubmission,
  IFormFieldOptionSubmissionData,
  IFormFieldSubmission,
  IFormFieldSubmissionData,
  IFormSubmission,
  IFormSubmissionData
} from './form-submission.types'
import { FormField, FormFieldOption, type IFormField, type IFormFieldOption } from '../form-field'
import type { IForm } from '../form/form.types'
import { Form } from '../form/form'

/**
 * A class representing a FormSubmission.
 */
export class FormSubmission extends AbstractModel implements IFormSubmission {
  readonly id?: number
  readonly form: IForm

  readonly created?: Date
  readonly modified?: Date

  constructor(data: IFormSubmissionData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormSubmissionData)[] = ['form']
    this.validate(data, requiredFields)

    this.form = new Form(data.form!)

    if (data.id) {
      this.id = data.id
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }
  }

  toString() {
    return `${this.form.toString()}${this.created ? ' - '.concat(format(this.created, 'p')) : ''}`
  }
}

/**
 * A class representing a FormFieldSubmission.
 */
export class FormFieldSubmission extends AbstractModel implements IFormFieldSubmission {
  readonly id?: number
  readonly field: IFormField
  readonly value: string = ''

  constructor(data: IFormFieldSubmissionData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormFieldSubmissionData)[] = ['field']
    this.validate(data, requiredFields)

    this.field = new FormField(data.field!)

    if (data.id) {
      this.id = data.id
    }

    if (data.value) {
      this.value = data.value
    }
  }

  toString() {
    return `Field submission: ${this.field.toString()}`
  }
}

/**
 * A class representing a FormFieldOptionSubmission.
 */
export class FormFieldOptionSubmission extends AbstractModel implements IFormFieldOptionSubmission {
  readonly id?: number
  readonly option: IFormFieldOption

  constructor(data: IFormFieldOptionSubmissionData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormFieldOptionSubmissionData)[] = ['option']
    this.validate(data, requiredFields)

    this.option = new FormFieldOption(data.option!)

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return `Field option submission: ${this.option.toString()}`
  }
}
