import { AbstractModel } from '../base'
import { Client, Site, type IClient, type ISite } from '../client'
import { Employee, type IEmployee } from '../employee'
import type { IShift, IShiftData } from './shift.types'
import { formatDateRange } from '@/utils/date'

/**
 * A class representing a Shift
 */
export class Shift extends AbstractModel implements IShift {
  readonly id?: number
  readonly site: ISite
  readonly start: Date
  readonly end: Date
  readonly client: IClient
  readonly guards: IEmployee[] = []

  readonly created?: Date
  readonly modified?: Date

  constructor(data: IShiftData) {
    super()

    // Validate data
    const requiredFields: (keyof IShiftData)[] = ['start', 'end']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.start = typeof data.start! === 'string' ? new Date(data.start!) : data.start!
    this.end = typeof data.end! === 'string' ? new Date(data.end!) : data.end!

    if (data.id) {
      this.id = data.id
    }

    this.site = new Site(data.site!)
    this.client = new Client(data.client!)

    if (data.guards) {
      this.guards = data.guards.map((guard) => new Employee(guard))
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }
  }

  toString() {
    return `${this.site.toString()}: ${formatDateRange(this.start, this.end)}`
  }

  dateRangeFormat(): string {
    return formatDateRange(this.start, this.end)
  }
}
