<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Vehicle Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mt-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form @submit.prevent ref="form" v-model="valid" validate-on="submit lazy">
        <v-row>
          <v-col md="6" lg="6" cols="12">
            <v-text-field
              v-model="vehicleData.plate"
              label="Plate*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              :rules="[requiredValidator]"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['plate'] as string[])"
            ></v-text-field>
          </v-col>
          <v-col md="6" lg="6" cols="12">
            <v-text-field
              v-model="vehicleData.year"
              label="Year*"
              type="number"
              variant="outlined"
              hide-details="auto"
              density="compact"
              min="1000"
              max="3000"
              minLength="4"
              maxLength="4"
              :rules="[requiredValidator]"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['year'] as string[])"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <VehicleMakeAutoCompletePicker
              v-model="selectedVehicleMake"
              @update:model-value="clearErrors()"
              label="Make*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              :readonly="!!selectedVehicleMake"
              clearable
              :rules="[requiredValidator]"
              :error-messages="
                error &&
                error.fieldErrors['model'] &&
                ((error.fieldErrors['model'] as IFieldErrors)['make'] as string[])
              "
            ></VehicleMakeAutoCompletePicker>
          </v-col>
          <v-col cols="12" md="6">
            <VehicleModelAutoCompletePicker
              v-model="selectedVehicleModel"
              label="Model*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              :vehicle-make="selectedVehicleMake"
              :disabled="!selectedVehicleMake"
              :readonly="!!selectedVehicleModel"
              clearable
              :rules="[requiredValidator]"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['model'] as string[])"
            ></VehicleModelAutoCompletePicker>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { cloneDeep } from 'lodash'
import { diffDeep } from '@/utils/helpers'
import { useCreateVehicle, useUpdateVehicle } from '@/composables/vehicle'
import { requiredValidator } from '@/utils/validators'

import {
  Vehicle,
  type IVehicle,
  type IVehicleData,
  type IVehicleMakeData,
  type IVehicleModelData
} from '@/models/fleet-management/vehicle'
import type { IFieldErrors, ISystemError } from '@/models/error'
import type { VForm } from 'vuetify/components'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import VehicleModelAutoCompletePicker from '@/components/fleet-management/vehicles/VehicleModelAutoCompletePicker.vue'
import VehicleMakeAutoCompletePicker from '@/components/fleet-management/vehicles/VehicleMakeAutoCompletePicker.vue'

interface Props {
  vehicle?: IVehicle
  isEdit: boolean
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

const vehicleData = ref<IVehicleData>({})
const selectedVehicleModel = ref<number | null>(null)
const selectedVehicleMake = ref<number | null>(null)

watch(dialog, (value) => {
  if (value) {
    clearErrors()
    vehicleData.value = !props.isEdit ? ({} as IVehicleData) : cloneDeep(props.vehicle!)

    selectedVehicleMake.value = props.isEdit
      ? ((vehicleData.value.model as IVehicleModelData).make as IVehicleMakeData).id!
      : null
    selectedVehicleModel.value = props.isEdit
      ? (vehicleData.value.model as IVehicleModelData).id!
      : null
  }
})

const form = ref<typeof VForm>()
const valid = ref(false)

const subtitle = computed(() => `${props.isEdit ? 'Update' : 'Create'} vehicle information`)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updateVehicle,
  reset: updateReset
} = useUpdateVehicle()
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createVehicle,
  reset: createReset
} = useCreateVehicle()

const localError = ref<ISystemError | null>(null)

const loading = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = computed({
  get: () => {
    return localError.value || props.isEdit ? updateError.value : createError.value
  },
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  props.isEdit ? updateReset() : createReset()
  form.value?.resetValidation()
  error.value = null
}

async function save() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    let vehicleInstance: IVehicle | null = null

    try {
      vehicleInstance = new Vehicle({ ...vehicleData.value, model: selectedVehicleModel.value! })
    } catch (e: any) {
      // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
      error.value = e
    }

    if (vehicleInstance != null) {
      const saveAction = props.isEdit ? updateVehicle : createVehicle

      let payload = vehicleInstance
      if (props.isEdit) {
        payload = diffDeep(vehicleInstance, props.vehicle, true)
      }

      saveAction(payload, {
        onSuccess: () => {
          closeDialog()
        }
      })
    }
  }
}
</script>
