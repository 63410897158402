<template>
  <BasePage title="" :fluid="false">
    <template #page-header:subtitle>
      <div class="d-flex flex-row align-center mb-1">
        <BackButton
          class="mx-4"
          ref="backButton"
          :fallback-to="{ name: 'post-orders-submissions' }"
        />

        <span>Post Orders Submission</span>
      </div>
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat class="d-flex flex-column rounded ma-4" color="white">
      <v-toolbar class="pt-5 px-7" id="toolbar" flat color="transparent">
        <v-toolbar-title class="align-self-center mt-1">
          <div class="d-flex flex-column grow">
            <v-img :src="logo" max-width="480" contain></v-img>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="d-flex flex-row">
          <strong class="mr-4 pt-3" style="font-size: 33px">Post Order Submission</strong>
        </div>
      </v-toolbar>

      <section class="d-flex mx-16 mt-6">
        <div class="d-flex flex-grow-1">
          <div class="flex-grow-1 pr-2">
            <template v-if="isLoading">
              <v-skeleton-loader
                class="mb-n2 mt-n3 ms-n3"
                width="216"
                color="transparent"
                type="heading"
              />
              <div class="d-flex flex-row">
                <v-skeleton-loader
                  class="my-0 mx-n3"
                  width="120"
                  color="transparent"
                  type="list-item@3"
                />
                <v-skeleton-loader
                  class="my-0 ms-n3"
                  width="120"
                  color="transparent"
                  type="list-item@3"
                />
              </div>
            </template>
            <div class="d-block address" v-else-if="submission">
              <strong>{{ submission.shift.client }}</strong>
              <div class="d-block address__field">
                <span>{{ submission.shift.site.address.address1 }} </span>
                <span>
                  {{
                    submission.shift.site.address.address2
                      ? `, ${submission.shift.site.address.address2}`
                      : ''
                  }}
                </span>
              </div>
              <div class="d-block address__field">
                <span class="wv-text wv-text--body">
                  {{ submission.shift.site.address.city }}
                  {{ submission.shift.site.address.region }}
                </span>
              </div>
              <div class="d-block address__field">
                <span class="wv-text wv-text--body">
                  {{ submission.shift.site.address.country }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="invoice-template-details" style="line-height: 1em">
          <template v-if="isLoading">
            <v-skeleton-loader class="mb-n2 mt-n3 me-n3" color="transparent" type="heading" />
            <div class="d-flex flex-row">
              <v-skeleton-loader
                class="my-0 me-n3"
                width="120"
                color="transparent"
                type="list-item@3"
              />
              <v-skeleton-loader
                class="my-0 ms-n3 me-n3"
                width="120"
                color="transparent"
                type="list-item@3"
              />
            </div>
          </template>
          <template v-else-if="submission">
            <table class="report-table">
              <tbody class="report-table__body">
                <tr class="report-table__row">
                  <td class="report-table__cell pr-3" colspan="1">
                    <strong class="wv-text--strong">Submission Id:</strong>
                  </td>
                  <td class="report-table__cell-info" colspan="1">
                    <span class="wv-text wv-text--body"> {{ submission?.submissionId }}</span>
                  </td>
                </tr>

                <tr class="report-table__row">
                  <td class="report-table__cell pr-3" colspan="1">
                    <strong class="wv-text--strong">Shift Start Time:</strong>
                  </td>
                  <td class="report-table__cell-info" colspan="1">
                    <span class="wv-text wv-text--body"
                      >{{ format(submission?.shift.start!, 'MMMM dd, yyyy - HH:mm') }}
                    </span>
                  </td>
                </tr>
                <tr class="report-table__row">
                  <td class="report-table__cell pr-3" colspan="1">
                    <strong class="wv-text--strong">Shift End Time:</strong>
                  </td>
                  <td class="report-table__cell-info" colspan="1">
                    <span class="wv-text wv-text--body"
                      >{{ format(submission?.shift.end!, 'MMMM dd, yyyy - HH:mm') }}
                    </span>
                  </td>
                </tr>
                <tr class="report-table__row">
                  <td class="report-table__cell pr-3" colspan="1">
                    <strong class="wv-text--strong">Guard:</strong>
                  </td>
                  <td class="report-table__cell-info" colspan="1">
                    <span class="wv-text wv-text--body"
                      >{{ submission?.shift.guards.join(', ') }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </section>

      <v-skeleton-loader
        v-if="isLoading"
        width="calc(100% - 100px)"
        class="mx-12 my-3"
        color="transparent"
        type="article"
      />

      <section class="mx-16 my-3" v-else-if="submission">
        <div class="d-flex flex-grow-1 justify-space-between align-center pt-1">
          <strong class="text-high-emphasis">Memo</strong>
        </div>
        <div class="text-medium-emphasis mb-10" :style="{ whiteSpace: 'pre-line' }">
          {{ submission.memo }}
        </div>
      </section>

      <section class="mx-8 mt-4" v-if="submission">
        <NoDataAvailablePlaceholder
          v-if="submission?.instructions_submissions.length == 0"
          style="margin-top: 32px !important; margin-bottom: 0 !important"
          header="No instruction submissions available"
        >
          <template #prepend>
            <v-card
              variant="flat"
              class="mask-bottom-card mb-3"
              color="transparent"
              width="180"
              height="120"
            >
              <v-list
                style="color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity))"
                variant="outlined"
                density="compact"
                lines="two"
                class="pt-0"
              >
                <v-list-item class="rounded-lg-xl mb-3 px-3">
                  <template v-slot:prepend>
                    <v-avatar size="32" class="me-n1" color="green-darken-2 text-white">
                      JD
                    </v-avatar>
                  </template>
                  <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
                </v-list-item>
                <v-list-item class="rounded-lg-xl mb-3 px-3">
                  <template v-slot:prepend>
                    <v-avatar size="32" class="me-n1" color="amber-darken-2 text-white">
                      DM
                    </v-avatar>
                  </template>
                  <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
                </v-list-item>
              </v-list>
            </v-card>
          </template>

          <template #description>
            <p class="mt-0 mb-6 text-caption text-medium-emphasis">
              <span>
                A <span class="text-primary">Post Order Instruction Submission</span> will be
                created from the Tag mobile app and can be viewed here</span
              >
            </p>
          </template>
        </NoDataAvailablePlaceholder>

        <PostOrderCheckpointObservationSubmission
          v-else
          v-for="(instruction, i) in submission.instructions_submissions"
          :key="i"
          :post-order-id="postOrderId"
          :post-order-submission-id="postOrderSubmissionId"
          :instruction="instruction"
          :reporter="submission.shift.guards.join(', ')"
        />
      </section>
    </v-card>
  </BasePage>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { computed, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import logo from '@/assets/full-logo-black.png'

import { useFetchPostOrdersSubmission } from '@/composables/post-order'

import type { IPostOrderSubmissionFilterParam } from '@/services/post_order'

import BasePage from '@/components/base/BasePage.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import BackButton from '@/components/common/BackButton.vue'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import PostOrderCheckpointObservationSubmission from '@/components/post-order/PostOrderInstructionObservationSubmission.vue'

const route = useRoute()
const router = useRouter()

// set default filters from route query param
// only use valid filter fields from routes query params including `search` and `page` and other valid fields
const filters = reactive<IPostOrderSubmissionFilterParam>({
  search: route.query.search as string,
  page: route.query.page ? Number(route.query.page) : 1,
  client: route.query.client ? Number(route.query.client) : undefined,
  site: route.query.site ? Number(route.query.site) : undefined,
  shift: route.query.shift ? Number(route.query.shift) : undefined
})

watch(
  filters,
  (value) => {
    /* this will update the URL query based on param values. */
    router
      .replace({
        name: route.name!,
        query: value as Record<string, any>
      })
      .catch((e: Error) => e) //catch navigation duplication error
  },
  {
    immediate: true
  }
)

const postOrderSubmissionId = computed(() => Number(route.params.id))

const { submission, isLoading, error } = useFetchPostOrdersSubmission(postOrderSubmissionId.value)

const postOrderId = computed(() => submission.value?.post_order!.id!)

function clearErrors() {
  error.value = null
}
</script>

<style lang="sass">
#toolbar
  .v-toolbar__content
      height: 80px

  .v-toolbar-title
      flex: 3 1

.report-table
  &__cell
    overflow: visible!important
    position: relative
    line-height: 1.3
    padding-bottom: 4px
    font-weight: regular
    vertical-align: middle
    text-align: right

  &__cell-info
    overflow: visible!important
    position: relative
    line-height: 1.3
    padding-bottom: 4px
    font-weight: regular
    vertical-align: middle
    text-align: left

    .wv-text
      width: 100%

  &__row
    border-width: 0
</style>
