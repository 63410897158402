import { format, isSameDay, isSameMinute } from 'date-fns'

/**
 * Formats a date range from start to end.
 *
 * @param {Date} start - The start date of the range.
 * @param {Date} end - The end date of the range.
 * @returns {string} A formatted string representing the date range.
 */
export function formatDateRange(start: Date, end: Date): string {
  // Check if start and end dates are valid
  if (!(start instanceof Date) || !(end instanceof Date)) {
    throw new Error('Invalid date range')
  }

  // Check if the start and end dates are the same
  if (isSameDay(start, end)) {
    // Check if the start and end times are the same
    if (isSameMinute(start, end)) {
      return format(start, 'MMMM dd, yyyy - HH:mm')
    } else {
      return format(start, 'MMMM dd, yyyy HH:mm').concat(` - ${format(end, 'HH:mm')}`)
    }
  }

  return `${format(start, 'MMMM d, yyyy HH:mm')} - ${format(end, 'MMMM d, yyyy HH:mm')}`
}
