<template>
  <v-autocomplete
    label="Model"
    v-model="vehicle"
    :items="vehicleModels"
    v-model:search="search"
    item-value="id"
    :item-title="
      (item: IVehicleModel) => {
        return item instanceof VehicleModel ? item.toString() : item
      }
    "
    :loading="loading"
    :error-messages="combinedErrorMessages"
    no-filter
    v-bind="$attrs"
  >
    <template #append-item v-if="!hideCreateBtn">
      <DropdownListItemAction :search="search" @click="addVehicleModel()" label="Create Model" />
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import { computed, type PropType, ref, watch } from 'vue'

import {
  useCreateVehicleModel,
  useFetchVehicleModels,
  type IVehicleModelPayload
} from '@/composables/vehicle-model'
import { isArray } from 'lodash'

import {
  VehicleModel,
  type IVehicleModel,
  type IVehicleModelData
} from '@/models/fleet-management/vehicle'
import { type ISystemError } from '@/models/error'
import DropdownListItemAction from '@/components/common/DropdownListItemAction.vue'

const props = defineProps({
  vehicleMake: {
    type: [Number, null] as PropType<number | null>,
    default: null,
    required: false
  },
  errorMessages: {
    type: [String, Array] as PropType<string | string[] | null>,
    default: () => []
  },
  hideCreateBtn: {
    type: Boolean,
    required: false,
    default: () => false
  }
})

const vehicle = defineModel<null | number | IVehicleModelData>({ default: null })
const search = ref('')

const localError = ref<ISystemError | null>(null)
const loading = computed(() => {
  return isFetchLoading.value || isCreatePending.value
})

const currentVehicleMake = ref(props.vehicleMake)

//reset selected vehicle model every time a vehicle make is selected
watch(
  () => props.vehicleMake,
  (value) => {
    currentVehicleMake.value = value
    search.value = ''
    vehicle.value = null
  }
)

// Call the composable with a reactive `vehicleModel`
const {
  vehicleModels,
  error: fetchError,
  isLoading: isFetchLoading
} = useFetchVehicleModels(currentVehicleMake)

const {
  isPending: isCreatePending,
  error: createError,
  mutate: createVehicleModel,
  reset: resetCreateVehicleModel
} = useCreateVehicleModel()

function addVehicleModel() {
  resetCreateVehicleModel()

  let vehicleModelInstance = null
  try {
    vehicleModelInstance = new VehicleModel({ name: search.value, make: currentVehicleMake.value! })
  } catch (error: any) {
    localError.value = error
  }

  if (vehicleModelInstance != null) {
    createVehicleModel(
      {
        makeId: currentVehicleMake.value,
        vehicleModel: vehicleModelInstance
      } as IVehicleModelPayload,
      {
        onSuccess: (vehicleModel) => {
          vehicle.value = vehicleModel.id!
        }
      }
    )
  }
}

const combinedErrorMessages = computed(() => {
  let error: string[] = []

  if (fetchError.value) {
    error.push(fetchError.value.message)
  }
  if (createError.value) {
    error.push(createError.value.message)
  }
  if (isArray(props.errorMessages)) {
    error.push(...props.errorMessages)
  } else if (props.errorMessages) {
    error.push(props.errorMessages)
  }

  return error
})
</script>
