<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Instruction Section Form Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mt-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form @submit.prevent ref="form" v-model="isFormValid" validate-on="submit lazy">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-text-field
              v-model="postOrderInstructionSectionData.section!.title"
              label="Title*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="
                error && ((error.fieldErrors['section'] as Record<string, any>).title as string[])
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="postOrderInstructionSectionData.type"
              :items="postOrderInstructionSectionTypes"
              label="Type*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              :disabled="props.isEdit"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['type'] as string[])"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="postOrderInstructionSectionData.section!.description"
              label="Description"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :error-messages="
                error &&
                ((error.fieldErrors['section'] as Record<string, any>).description as string[])
              "
            />
          </v-col>

          <v-col cols="12" class="pb-0">
            <FileClipboardInput
              v-model="postOrderInstructionSectionData.photo"
              accept="'jpg', 'jpeg', 'png', 'gif'"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['photo'] as string[])"
              clearable
            />
          </v-col>

          <v-col cols="12" class="pt-0 pb-6">
            <div class="d-flex align-center" style="height: 20px">
              <span class="v-label mr-2">Internal use only</span>
              <v-checkbox
                v-model="postOrderInstructionSectionData.internal"
                hide-details="auto"
                density="compact"
                @update:model-value="clearErrors()"
                :error-messages="error && (error.fieldErrors['internal'] as string[])"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'

import { diffDeep } from '@/utils/helpers'
import { cloneDeep } from 'lodash'
import { requiredValidator } from '@/utils/validators'

import {
  useCreatePostOrderInstructionsSection,
  useCreatePostOrderInstructionsVehicleLogSection,
  useUpdatePostOrderInstructionsSection
} from '@/composables/post-order'

import {
  PostOrderInstructionSectionTypeEnum,
  PostOrderInstructionsSection,
  type IPostOrderInstructionsSection,
  type IPostOrderInstructionsSectionData
} from '@/models/post-order'
import type { VForm } from 'vuetify/components/VForm'
import type { ISystemError } from '@/models/error'
import { FormFieldTypeEnum } from '@/models/form/form-field/form-field.constants'
import { PostOrderSymbol } from './postOrderProvide'

import AlFormCard from '../common/AlFormCard.vue'
import ErrorAlert from '../common/ErrorAlert.vue'
import FileClipboardInput from '../common/FileClipboardInput.vue'

interface Props {
  postOrderInstructionSection: IPostOrderInstructionsSectionData | null
  isEdit: boolean
}

const props = defineProps<Props>()

interface Emits {
  (e: 'saved-section', section: IPostOrderInstructionsSection): void
}
const emit = defineEmits<Emits>()

const dialog = defineModel<boolean>('dialog', { default: false })

const subtitle = computed(
  () => `${props.isEdit ? 'Update' : 'Create'} post order instruction information`
)

const postOrderInstructionSectionData = ref<IPostOrderInstructionsSectionData>({})

watch(dialog, (value) => {
  if (value) {
    clearErrors()

    postOrderInstructionSectionData.value = cloneDeep(props.postOrderInstructionSection!)
  }
})

const postOrderInstructionSectionTypes = [
  {
    title: 'Checkpoint',
    value: PostOrderInstructionSectionTypeEnum.Checkpoint
  },
  {
    title: 'Vehicle Log',
    value: PostOrderInstructionSectionTypeEnum.VehicleLog
  }
]

const form = ref<VForm>()
const isFormValid = ref(false)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const postOrderContext = inject(PostOrderSymbol)

if (!postOrderContext) throw new Error('[Post Order] Could not find injected post order context')

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updatePostOrderInstructionSection,
  reset: updateReset
} = useUpdatePostOrderInstructionsSection(
  postOrderContext.postOrderId,
  postOrderContext.instructionId
)

const {
  isPending: isCreatePending,
  error: createError,
  mutate: createPostOrderInstructionSection,
  reset: createReset
} = useCreatePostOrderInstructionsSection(
  postOrderContext.postOrderId,
  postOrderContext.instructionId
)

const {
  isPending: isCreateVehicleLogPending,
  error: createVehicleLogError,
  mutate: createPostOrderInstructionsVehicleLogSection,
  reset: createVehicleLogReset
} = useCreatePostOrderInstructionsVehicleLogSection(
  postOrderContext.postOrderId,
  postOrderContext.instructionId
)

const loading = computed(() =>
  props.isEdit
    ? isUpdatePending.value
    : postOrderInstructionSectionData.value.type == PostOrderInstructionSectionTypeEnum.VehicleLog
      ? isCreateVehicleLogPending.value
      : isCreatePending.value
)

const localError = ref<ISystemError | null>(null)

const error = computed({
  get: () => {
    return localError.value || props.isEdit
      ? updateError.value
      : postOrderInstructionSectionData.value.type == PostOrderInstructionSectionTypeEnum.VehicleLog
        ? createVehicleLogError.value
        : createError.value
  },
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  props.isEdit
    ? updateReset()
    : postOrderInstructionSectionData.value.type == PostOrderInstructionSectionTypeEnum.VehicleLog
      ? createVehicleLogReset()
      : createReset()
  error.value = null
}

async function save() {
  clearErrors()

  const { valid } = await form.value!.validate()

  if (valid) {
    let sectionInstance: IPostOrderInstructionsSection | null = null

    try {
      //Create a custom field specifically to store vehicle information
      if (
        !props.isEdit &&
        postOrderInstructionSectionData.value.type == PostOrderInstructionSectionTypeEnum.VehicleLog
      )
        postOrderInstructionSectionData.value.fields = [
          {
            field: {
              label: 'Untitled',
              type: FormFieldTypeEnum.Custom,
              order: 1,
              config: {}
            }
          }
        ]

      sectionInstance = new PostOrderInstructionsSection(postOrderInstructionSectionData.value)
    } catch (e: any) {
      // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
      error.value = e
    }

    if (sectionInstance != null) {
      let payload = sectionInstance
      if (props.isEdit) {
        payload = diffDeep(sectionInstance, props.postOrderInstructionSection, true)
      }

      const saveAction = props.isEdit
        ? updatePostOrderInstructionSection
        : sectionInstance.type == PostOrderInstructionSectionTypeEnum.VehicleLog
          ? createPostOrderInstructionsVehicleLogSection
          : createPostOrderInstructionSection

      saveAction(payload, {
        onSuccess: (section) => {
          emit('saved-section', new PostOrderInstructionsSection(section))
          closeDialog()
        }
      })
    }
  }
}
</script>
