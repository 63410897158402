<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Post Order Form Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mt-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form @submit.prevent ref="form" v-model="isDetailsValid" validate-on="submit lazy">
        <v-row class="mt-1">
          <v-col cols="12">
            <ClientAutoCompletePicker
              clearable
              label="Client*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              v-model="selectedClient"
              @update:model-value="selectedClientHandler"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['template'] as string[])"
            />
          </v-col>

          <v-col cols="12">
            <SiteAutoCompletePicker
              clearable
              v-model="postOrderData.site"
              :disabled="!selectedClient"
              :client="selectedClient"
              label="Site"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              validate-on="input"
              :rules="[requiredValidator, preExistingPostOrderRule]"
              :error-messages="[...(error ? (error.fieldErrors['site'] as string[]) : [])]"
            />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { SystemError, type ISystemError } from '@/models/error'
import type { VForm } from 'vuetify/components'

import { diffDeep } from '@/utils/helpers'
import { cloneDeep } from 'lodash'
import { requiredValidator } from '@/utils/validators'
import postOrderService from '@/services/post_order/post-order'

import { useQueryClient } from '@tanstack/vue-query'
import { useCreatePostOrder, useUpdatePostOrder } from '@/composables/post-order'

import { PostOrder, type IPostOrder, type IPostOrderData } from '@/models/post-order'
import type { IClientData } from '@/models/client'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import ClientAutoCompletePicker from '@/components/clients/ClientAutoCompletePicker.vue'
import SiteAutoCompletePicker from '@/components/sites/SiteAutoCompletePicker.vue'

interface Props {
  postOrder?: IPostOrderData
  isEdit: boolean
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

const postOrderData = ref<IPostOrderData>({})

watch(dialog, (value) => {
  if (value) {
    clearErrors()
    selectedClient.value = null
    postOrderData.value = !props.postOrder ? ({} as IPostOrderData) : cloneDeep(props.postOrder)
  }
})

const form = ref<VForm>()
const isDetailsValid = ref(false)

const selectedClient = ref<number | null>()

const subtitle = computed(() => `${props.isEdit ? 'Update' : 'Create'} form template information`)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updatePostOrder,
  reset: updateReset
} = useUpdatePostOrder()
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createPostOrder,
  reset: createReset
} = useCreatePostOrder()

const localError = ref<ISystemError | null>(null)

const loading = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = computed({
  get: () => {
    return localError.value || props.isEdit ? updateError.value : createError.value
  },
  set: (value) => {
    localError.value = value
  }
})

function selectedClientHandler(client: number | IClientData | null) {
  clearErrors()
  if (!client) {
    postOrderData.value.site = undefined
  }
}

function clearErrors() {
  props.isEdit ? updateReset() : createReset()
  error.value = null
}

async function save() {
  clearErrors()

  const { valid } = await form.value!.validate()

  if (valid) {
    let postOrderInstance: IPostOrder | null = null

    try {
      postOrderInstance = new PostOrder(postOrderData.value)
    } catch (e: any) {
      // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
      error.value = e
    }

    if (postOrderInstance != null) {
      const saveAction = props.isEdit ? updatePostOrder : createPostOrder

      let payload = postOrderInstance
      if (props.isEdit) {
        payload = diffDeep(postOrderInstance, props.postOrder, true)
      }

      saveAction(payload, {
        onSuccess: () => {
          closeDialog()
        }
      })
    }
  }
}
const queryClient = useQueryClient()

type ValidationResult = string | boolean
type ValidationRule =
  | ValidationResult
  | PromiseLike<ValidationResult>
  | ((value: any) => ValidationResult)
  | ((value: any) => PromiseLike<ValidationResult>)

const preExistingPostOrderRule: ValidationRule = async (value: any) => {
  if (!value) return true

  let postOrders = []
  try {
    postOrders = await queryClient.fetchQuery({
      queryKey: ref(['post-orders-site', computed(() => postOrderData.value.site)]),
      queryFn: () => postOrderService.fetchPostOrders({ site: postOrderData.value.site as number })
    })
    return postOrders.length == 0 || 'A Post order already exists for this site'
  } catch (error) {
    return error instanceof SystemError ? error.detail : true //"Failed to fetch post orders, let server handle error"
  }
}
</script>
