<template>
  <NoDataAvailablePlaceholder
    v-if="report.activities.length == 0"
    style="margin-top: 32px !important; margin-bottom: 0 !important"
    header="No activities available"
    :description="
      currentUser?.hasPermission('guard_reports.add_guardreportactivity')
        ? 'No activities available, you can create an activity here'
        : ''
    "
  >
    <template #primaryAction>
      <v-btn
        v-if="currentUser?.hasPermission('guard_reports.add_guardreportactivity')"
        prepend-icon=""
        class="text-capitalize"
        @click="createGuardReportActivity()"
      >
        <v-icon left class="hidden-sm-and-down mr-1"> {{ route.meta.icon }} </v-icon>
        Create Activity
      </v-btn>
    </template>
  </NoDataAvailablePlaceholder>

  <div v-else v-for="(activity, i) in report.activities" :key="i" class="mb-3">
    <GuardReportDetailActivityCardHeader
      :report="report"
      :activity="activity"
      @refresh-report="updateReportCache()"
    />

    <div>
      <v-row class="ma-0 mb-4" v-for="(observation, i) in activity.observations" :key="i">
        <GuardReportDetailActivityObservationCard
          :report="report"
          :activity="activity"
          :observation="observation"
          @open-activity-observation-detail="openActivityObservationDialog(activity, observation)"
          @refresh-report="updateReportCache()"
          @incident-report-selected="openIncidentReportDialog"
          @incident-report-created="incidentReportCreatedHandler"
        />
      </v-row>
      <v-row
        class="d-flex ma-0"
        v-if="currentUser?.hasPermission('guard_reports.change_guardreport')"
      >
        <v-col class="pr-0">
          <v-btn
            block
            flat
            variant="tonal"
            color="secondary"
            class="text-capitalize"
            @click="openActivityObservationDialog(activity)"
          >
            <v-icon left class="hidden-sm-and-down mr-1">mdi-eye </v-icon>
            Create observation
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>

  <v-btn
    v-if="report.activities.length > 0"
    block
    flat
    class="text-capitalize mb-4"
    color="secondary"
    @click="createGuardReportActivity()"
    :loading="isCreateActivityLoading"
  >
    <v-icon left class="hidden-sm-and-down mr-1"> {{ route.meta.icon }} </v-icon>
    Create Activity
  </v-btn>

  <GuardReportActivityDetailTimeFormDialog
    v-if="selectedActivity"
    v-model:dialog="activityDetailDialog"
    :activity="selectedActivity"
    :is-edit="!!selectedActivity"
    :report-id="report.id!"
  />

  <GuardReportDetailObservationFormDialog
    v-if="selectedActivity"
    :report="report"
    v-model:dialog="activityDetailObservationDialog"
    :activity-id="selectedActivity?.id!"
    :is-edit="!!selectedObservation"
    :observation="selectedObservation"
    @saved-activity-observation="updateReportCache()"
    @incident-report-created="incidentReportCreatedHandler"
  />

  <IncidentReportDetailDialog
    v-model="incidentReportDetailDialog"
    :report-id="selectedIncidentReportId"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { storeToRefs } from 'pinia'

import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores'
import { useCreateGuardReportActivity, useFetchGuardReport } from '@/composables/guard-report'
import { useQueryClient } from '@tanstack/vue-query'
import { useCreateIncidentReportFromObservation } from '@/composables/incident-report'

import {
  type IGuardReportActivityObservation,
  type IGuardReport,
  type IGuardReportActivity,
  GuardReportActivity,
  type IIncidentReportActivityObservationData
} from '@/models/report'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'

import GuardReportActivityDetailTimeFormDialog from './GuardReportActivityDetailTimeFormDialog.vue'
import GuardReportDetailObservationFormDialog from './GuardReportDetailObservationFormDialog.vue'
import GuardReportDetailActivityCardHeader from './GuardReportDetailActivityCardHeader.vue'
import GuardReportDetailActivityObservationCard from './GuardReportDetailActivityObservationCard.vue'
import IncidentReportDetailDialog from '../incident/IncidentReportDetailDialog.vue'

interface Props {
  report: IGuardReport
}

const queryClient = useQueryClient()

const props = defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const route = useRoute()

const { queryKey } = useFetchGuardReport(props.report.id!)

const activityDetailDialog = ref(false)
const activityDetailObservationDialog = ref(false)

const selectedActivity = ref<IGuardReportActivity>()
const selectedObservation = ref<IGuardReportActivityObservation>()

function updateReportCache() {
  queryClient.invalidateQueries({ queryKey })
}

const { isPending: isCreateActivityLoading, mutate: createActivityMutate } =
  useCreateGuardReportActivity(props.report.id!)

function createGuardReportActivity() {
  createActivityMutate(undefined, {
    onSuccess: (activity) => {
      openActivityObservationDialog(new GuardReportActivity(activity))
    }
  })
}

function openActivityObservationDialog(
  activity: IGuardReportActivity,
  observation?: IGuardReportActivityObservation
) {
  selectedObservation.value = observation
  selectedActivity.value = activity

  activityDetailObservationDialog.value = true
}

const incidentReportDetailDialog = ref(false)

const { selectedIncidentReportId, incidentReportCreatedHandler: _incidentReportCreatedHandler } =
  useCreateIncidentReportFromObservation(() => {
    incidentReportDetailDialog.value = true
  })

function incidentReportCreatedHandler(data: {
  observation: IGuardReportActivityObservation
  incidentReportId: number
  fileAttachment?: File | string | null
}) {
  const payload = {
    observation: {
      location: data.observation.checkpoint,
      text: data.observation.text
    } as IIncidentReportActivityObservationData,
    incidentReportId: data.incidentReportId,
    fileAttachment: data.fileAttachment
  }

  _incidentReportCreatedHandler(payload)
}

function openIncidentReportDialog(incidentReportId: number) {
  incidentReportDetailDialog.value = true

  selectedIncidentReportId.value = incidentReportId
}
</script>
