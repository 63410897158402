export enum PostOrderInstructionTypeEnum {
  Start,
  Patrol,
  End
}

export enum PostOrderInstructionSectionTypeEnum {
  Checkpoint,
  VehicleLog
}

export enum PostOrderInstructionStateEnum {
  Draft,
  Published
}

/** #### SUBMISSION #### */

export enum PostOrderInstructionsSectionSubmissionStateEnum {
  Todo,
  Completed,
  NotApplicable
}
