import { computed, ref, toRef, type MaybeRef } from 'vue'

import { VehicleModel, type IVehicleModelData } from '@/models/fleet-management/vehicle'

import vehicleMakeService from '@/services/fleet_management/vehicle-make/vehicle-make'

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

export interface IVehicleModelPayload {
  makeId: number
  vehicleModel: IVehicleModelData
}

export function useFetchAllVehicleModels() {
  const queryKey = ref(['all-vehicle-models'])

  const count = ref<number>(0)

  const {
    isLoading,
    isError,
    error,
    data: vehicleModels
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => vehicleMakeService.fetchAllVehicleModels(),

    select: (data) => data.map((vehicleModel) => new VehicleModel(vehicleModel))
  })

  return { queryKey, vehicleModels, count, error, isError, isLoading }
}

export function useFetchVehicleModels(id: MaybeRef<number | null>) {
  const vehicleMakeIdRef = toRef(id)

  const queryKey = ref(['vehicle-models', vehicleMakeIdRef])

  const {
    data: vehicleModels,
    isLoading,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(vehicleMakeIdRef.value)),
    queryFn: () => vehicleMakeService.fetchVehicleModels(vehicleMakeIdRef.value!),
    select: (data) => data.map((vehicleModel) => new VehicleModel(vehicleModel))
  })

  return { vehicleModels, isPending, isLoading, error, queryKey, vehicleMakeIdRef }
}

export function useFetchVehicleModel(makeId: MaybeRef<number>, id: MaybeRef<number>) {
  const vehicleMakeIdRef = toRef(makeId)
  const vehicleModelIdRef = toRef(id)

  const queryKey = ref(['vehicle-model', vehicleModelIdRef])

  const {
    data: vehicleModel,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(vehicleModelIdRef.value)),
    queryFn: () =>
      vehicleMakeService.fetchVehicleModel(vehicleMakeIdRef.value, vehicleModelIdRef.value),
    select: (data) => new VehicleModel(data)
  })

  return { vehicleModel, isPending, error, queryKey }
}

export function useCreateVehicleModel() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-vehicle-model'],
    mutationFn: (vehicleModelPayload: IVehicleModelPayload) =>
      vehicleMakeService.createVehicleModel(
        vehicleModelPayload.makeId,
        vehicleModelPayload.vehicleModel
      ),

    onSuccess: (data) => {
      queryClient.setQueryData(['vehicle-model', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-models']) })
      snackbarStore.showSnackbar('VehicleModel created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateVehicleModel() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-vehicle-model'],
    mutationFn: (vehicleModelPayload: IVehicleModelPayload) =>
      vehicleMakeService.updateVehicleModel(
        vehicleModelPayload.makeId,
        vehicleModelPayload.vehicleModel
      ),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-model', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-models']) })
      snackbarStore.showSnackbar('Vehicle Model updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteVehicleModel(makeId: MaybeRef<number>) {
  const vehicleMakeIdRef = toRef(makeId)

  const mutationKey = ref(['delete-vehicle-model'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => vehicleMakeService.deleteVehicleModel(vehicleMakeIdRef.value, id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-models']) })
      snackbarStore.showSnackbar('Vehicle Model deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}
