import { computed, ref, toRef, type MaybeRef } from 'vue'

import {
  VehicleLog,
  VehicleLogAttachment,
  type IVehicleLogAttachmentData,
  type IVehicleLogData
} from '@/models/fleet-management/vehicle'

import vehicleLogService from '@/services/fleet_management/vehicle-log/vehicle-log'

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

export function useFetchVehicleLogs() {
  const queryKey = ref(['vehicle-logs'])

  const {
    isLoading,
    isError,
    error,
    data: logs
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => vehicleLogService.fetchVehicleLogs(),
    select: (data) => data.map((log) => new VehicleLog(log))
  })

  return { queryKey, logs, error, isError, isLoading }
}

export function useFetchVehicleLog(logId: MaybeRef<number>) {
  const vehicleLogIdRef = toRef(logId)

  const queryKey = ref(['vehicle-log', vehicleLogIdRef])

  const {
    data: log,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => vehicleLogService.fetchVehicleLog(vehicleLogIdRef.value),
    select: (data) => new VehicleLog(data)
  })

  return { log, isPending, error, queryKey }
}

export function useCreateVehicleLog() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-vehicle-log'],
    mutationFn: (vehicle: IVehicleLogData) => vehicleLogService.createVehicleLog(vehicle),

    onSuccess: (data) => {
      queryClient.setQueryData(['vehicle-log', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-logs']) })
      snackbarStore.showSnackbar('Vehicle Log created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateVehicleLog() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-vehicle-log'],
    mutationFn: (vehicle: IVehicleLogData) => vehicleLogService.updateVehicleLog(vehicle),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-log', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-logs']) })
      snackbarStore.showSnackbar('Vehicle Log updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteVehicleLog() {
  const mutationKey = ref(['delete-vehicle-log'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => vehicleLogService.deleteVehicleLog(id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-logs']) })
      snackbarStore.showSnackbar('Vehicle Log deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Attachments
export function useFetchVehicleLogAttachments(logId: MaybeRef<number>) {
  const vehicleLogIdRef = toRef(logId)
  const queryKey = ref(['vehicles-log-attachments', vehicleLogIdRef])

  const {
    isLoading,
    isPending,
    isError,
    error,
    data: attachments
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => vehicleLogService.fetchVehicleLogAttachments(vehicleLogIdRef.value),

    select: (data) => data.map((logs) => new VehicleLogAttachment(logs))
  })

  return { queryKey, attachments, error, isError, isPending, isLoading }
}

export function useFetchVehicleLogAttachment(
  logId: MaybeRef<number>,
  attachmentId: MaybeRef<number>
) {
  const vehicleLogIdRef = toRef(logId)
  const vehicleLogAttachmentIdRef = toRef(attachmentId)

  const queryKey = ref(['vehicle-log-attachment', vehicleLogIdRef, vehicleLogAttachmentIdRef])

  const {
    data: log,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(vehicleLogIdRef.value)),
    queryFn: () =>
      vehicleLogService.fetchVehicleLogAttachment(
        vehicleLogIdRef.value,
        vehicleLogAttachmentIdRef.value
      ),
    select: (data) => new VehicleLog(data)
  })

  return { log, isPending, error, queryKey }
}

export function useCreateVehicleLogAttachment(logId: MaybeRef<number>) {
  const vehicleLogIdRef = toRef(logId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-vehicle-log-attachment'],
    mutationFn: (vehicle: IVehicleLogAttachmentData) =>
      vehicleLogService.createVehicleLogAttachment(vehicleLogIdRef.value, vehicle),

    onSuccess: (data) => {
      queryClient.setQueryData(['vehicle-log-attachment', vehicleLogIdRef.value, data.id], data)
      queryClient.invalidateQueries({
        queryKey: ref(['vehicle-log-attachments', vehicleLogIdRef.value])
      })
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-logs']) })
      snackbarStore.showSnackbar('Vehicle Log Attachment created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateVehicleLogAttachment(logId: MaybeRef<number>) {
  const vehicleLogIdRef = toRef(logId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-vehicle-log-attachment'],
    mutationFn: (vehicle: IVehicleLogAttachmentData) =>
      vehicleLogService.updateVehicleLogAttachment(vehicleLogIdRef.value, vehicle),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ref(['vehicle-log-attachment', vehicleLogIdRef.value, data.id])
      })
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-log-attachments', vehicleLogIdRef]) })
      snackbarStore.showSnackbar('Vehicle Log Attachment updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteVehicleLogAttachment(logId: MaybeRef<number>) {
  const vehicleLogIdRef = toRef(logId)

  const mutationKey = ref(['delete-vehicle-log-attachment'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) =>
      vehicleLogService.deleteVehicleLogAttachment(vehicleLogIdRef.value, id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-log-attachments', vehicleLogIdRef]) })
      snackbarStore.showSnackbar('Vehicle Log Attachment deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}
