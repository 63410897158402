<template>
  <MoreOptionsMenuButton v-bind="attrs" v-if="currentUser?.hasPermission('clients.delete_client')">
    <v-list-item
      v-if="currentUser.hasPermission('clients.delete_client')"
      class="text-error"
      @click="emit('post-order-delete-pressed', postOrder)"
      title="Delete Post Order"
    >
      <template #prepend>
        <v-icon size="18" icon="mdi-delete" class="me-1" />
      </template>
    </v-list-item>
  </MoreOptionsMenuButton>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue'
import { storeToRefs } from 'pinia'

import type { IPostOrder } from '@/models/post-order'

import { useAuthStore } from '@/stores'

import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'

interface Props {
  postOrder: IPostOrder
}

defineOptions({
  name: 'PostOrderDetailOptionsButton'
})

defineProps<Props>()

interface Emit {
  (e: 'post-order-delete-pressed', postOrder: IPostOrder): void
}
const emit = defineEmits<Emit>()

const attrs = useAttrs()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>
