<template>
  <v-list-item
    lines="three"
    class="position-relative border px-3 pb-1 rounded-lg mb-2"
    variant="outlined"
  >
    <ErrorAlert
      v-if="error != null"
      :error="error"
      @clearErrors="clearErrors()"
      class="mt-0 mb-4 rounded"
    />
    <v-list-item-title class="w-full d-flex flex-row flex-grow-1 align-center pb-1">
      <div class="text-subtitle-2 d-block address mt-2 text-wrap" v-if="site.address">
        <div class="d-block address__field">{{ site.address.address1 }}</div>
        <div class="d-block address__field">
          {{ site.address.address2 }}
        </div>

        <div class="d-block address__field">
          <span class="wv-text wv-text--body">
            {{ site.address.city }},
            {{ site.address.region }}
          </span>
        </div>
        <div class="d-block address__field">
          <span class="wv-text wv-text--body">
            <span> {{ site.address.country }}, {{ site.address.postal_code }} </span>
          </span>
        </div>
      </div>

      <div class="ml-auto align-self-start">
        <MoreOptionsMenuButton
          icon
          variant="text"
          color="medium-emphasis"
          :size="26"
          v-if="
            actionable &&
            (currentUser?.hasPermission('clients.change_site') ||
              currentUser?.hasPermission('clients.delete_site') ||
              currentUser?.hasPermission('cameras.view_camera') ||
              currentUser?.hasPermission('post_orders.change_postorder') ||
              currentUser?.hasPermission('post_orders.add_postorder'))
          "
        >
          <v-progress-linear :active="isLoading" height="2" indeterminate color="primary" />
          <v-list-item
            v-if="canManagePostOrder"
            :to="{
              name: 'post-order-instruction-detail',
              params: {
                clientId: clientId,
                siteId: site.code,
                id: postOrders && postOrders[0]?.id
              }
            }"
            title="Manage post order"
          />
          <v-list-item v-else-if="canCreatePostOrder" @click="addPostOrder()">
            <v-list-item-title>
              <template v-if="!isCreatePostOrderPending">Create post order</template>
              <template v-else>
                <v-progress-circular indeterminate color="primary" size="20" width="2" />
              </template>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="Array.isArray(cameras) && currentUser.hasPermission('cameras.view_camera')"
            @click="toggleCameraView(!isSelected)"
            :title="`${isSelected ? 'Hide' : 'View'} Cameras`"
          />
          <v-list-item
            v-if="currentUser.hasPermission('clients.change_site')"
            @click="$emit('site-edit-pressed', site)"
            title="Edit Site"
          />

          <template v-if="currentUser.hasPermission('clients.delete_site')">
            <v-divider class="mb-1" />

            <ConfirmationDialog
              width="auto"
              title="Delete site"
              v-model="deleteSiteDialog"
              v-model:error="deleteSiteError"
            >
              <template #activator="{ props }">
                <v-list-item title="Delete Site" class="text-error" v-bind="props" />
              </template>

              <template #message>
                Are you sure you want to delete site
                <span class="text-medium-high-emphasis font-weight-bold">{{ site.code }}</span
                >?
                <br />
                <div class="pt-2">This action cannot be undone.</div>
              </template>

              <template #actions>
                <v-spacer></v-spacer>
                <v-btn
                  variant="flat"
                  :disabled="siteDeleteInProgress"
                  @click="closeDeleteSiteDialog()"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="error"
                  variant="flat"
                  :loading="siteDeleteInProgress"
                  @click="removeSite(site.code!)"
                >
                  Delete
                </v-btn>
              </template>
            </ConfirmationDialog>
          </template>
        </MoreOptionsMenuButton>
      </div>
    </v-list-item-title>
    <v-list-item-subtitle class="pb-2 mt-1 text-medium-emphasis">
      <div class="d-flex flex-column font-weight-medium">
        Site Number
        <div class="text-black font-weight-bold">{{ site.code }}</div>
      </div>
    </v-list-item-subtitle>

    <v-list-subheader
      v-if="Array.isArray(cameras) && currentUser?.hasPermission('cameras.view_camera')"
      density="comfortable"
      class="site-subheader flex-grow-1 d-inline-flex align-center font-weight-medium px-0 ms-n2 w-100 mb-2"
    >
      <div class="text-caption d-inline-flex align-center justify-start">
        <div class="align-self-center font-weight-medium text-high-emphasis me-2">Camera Views</div>

        <v-fade-transition mode="out-in">
          <v-progress-circular
            v-if="camerasLoading"
            indeterminate
            color="primary"
            width="2"
            size="20"
          />
          <v-card
            v-else
            variant="tonal"
            width="24"
            height="24"
            style="display: inline-grid"
            class="align-center justify-center text-caption font-weight-bold"
          >
            {{ cameras.length }}
          </v-card>
        </v-fade-transition>
      </div>
      <div class="ml-auto" v-if="actionable">
        <v-btn
          class="text-caption text-none font-weight-regular text-medium-emphasis mr-n6 px-4"
          size="small"
          variant="text"
          height="20"
          @click="toggleCameraView(!isSelected)"
        >
          {{ !isSelected ? 'Show' : 'Hide' }}
          <v-icon end :icon="!isSelected ? '$collapse' : '$expand'"></v-icon>
        </v-btn>
      </div>
    </v-list-subheader>

    <!-- site cameras list -->
    <template v-if="Array.isArray(cameras) && currentUser?.hasPermission('cameras.view_camera')">
      <v-expand-transition>
        <CameraList
          :site="site"
          :client-id="clientId"
          v-if="isSelected && isCamerasSelected"
          :cameras="cameras"
        />
      </v-expand-transition>
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'
import { useDeleteSite } from '@/composables/site'
import { useCreatePostOrder, useFetchPostOrders } from '@/composables/post-order'

import type { ISite } from '@/models/client'
import type { ICamera } from '@/models/camera'
import type { ISystemError } from '@/models/error'
import { PostOrder, type IPostOrder, type IPostOrderData } from '@/models/post-order'
import type { IPostOrderFilterParam } from '@/services/post_order'

import CameraList from '../cameras/CameraList.vue'
import ConfirmationDialog from '../common/ConfirmationDialog.vue'
import MoreOptionsMenuButton from '../common/MoreOptionsMenuButton.vue'
import ErrorAlert from '../common/ErrorAlert.vue'

interface Props {
  clientId: number
  site: ISite

  // camera
  cameras?: ICamera[]
  camerasLoading?: boolean

  isSelected: boolean
  actionable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  actionable: true
})

watch(
  () => props.isSelected,
  (value) => {
    if (!value) {
      isCamerasSelected.value = false
    }
  }
)

interface Emit {
  (e: 'site-selected', selected: boolean): void
  (e: 'site-edit-pressed', site: ISite): void
  (e: 'site-create-pressed'): void
}
const emit = defineEmits<Emit>()

const router = useRouter()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

function toggleCameraView(value: boolean) {
  isCamerasSelected.value = value
  emit('site-selected', value)
}

const isCamerasSelected = ref(false)

const deleteSiteDialog = ref(false)
function closeDeleteSiteDialog() {
  deleteSiteDialog.value = false
}

const clientId = computed(() => props.clientId!)

const {
  isPending: siteDeleteInProgress,
  error: deleteSiteError,
  mutate: deleteSite,
  reset: clearSiteErrors
} = useDeleteSite(clientId)

function removeSite(code: number) {
  clearSiteErrors()
  deleteSite(code, {
    onSuccess: () => {
      closeDeleteSiteDialog()
    }
  })
}

const postOrderFilters = reactive<IPostOrderFilterParam>({
  site: props.site.code
})

// When the prop changes then update
watch(
  () => props.site,
  (value) => {
    if (value) {
      postOrderFilters.site = value.code
    }
  }
)

// CORE
const error = ref<ISystemError | null>(null)

function clearErrors() {
  error.value = null
}

const { postOrders, isLoading, error: fetchPostOrderError } = useFetchPostOrders(postOrderFilters)

watch(fetchPostOrderError, (value) => {
  if (value) {
    error.value = value
  }
})

const {
  isPending: isCreatePostOrderPending,
  error: postOrderCreateError,
  mutate: createPostOrder,
  reset: clearCreatePostOrderErrors
} = useCreatePostOrder()

watch(postOrderCreateError, (value) => {
  if (value) {
    error.value = value
  }
})

async function addPostOrder() {
  clearCreatePostOrderErrors()

  const postOrderData: IPostOrderData = { site: props.site.code, client: props.clientId }

  let postOrderInstance: IPostOrder | null = null

  try {
    postOrderInstance = new PostOrder(postOrderData)
  } catch (e: any) {
    // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
    error.value = e
  }

  if (postOrderInstance != null) {
    let payload = postOrderInstance

    createPostOrder(payload, {
      onSuccess: (data) => {
        router.push({
          name: 'post-order-instruction-detail',
          params: {
            clientId: props.clientId,
            siteId: props.site.code,
            id: data.id
          }
        })
      }
    })
  }
}

const canManagePostOrder = computed(
  () =>
    !isLoading.value &&
    postOrders.value &&
    postOrders.value.length > 0 &&
    currentUser.value?.hasPermission('post_orders.change_postorder')
)

const canCreatePostOrder = computed(
  () =>
    postOrders.value &&
    postOrders.value.length == 0 &&
    currentUser.value?.hasPermission('post_orders.add_postorder')
)
</script>
