<template>
  <v-autocomplete
    label="Shift"
    :items="shifts"
    v-model="shift"
    v-model:search="queryFilters.search"
    :item-props="shiftListItemProps"
    :loading="isLoading"
    :error-messages="combinedErrorMessages"
    no-filter
    v-bind="$attrs"
  ></v-autocomplete>
</template>

<script setup lang="ts">
import { computed, reactive, type PropType } from 'vue'

import type { IShiftFilterParam } from '@/services'
import { Shift, type IShift } from '@/models/shift'

import { useFetchShifts } from '@/composables/shift'

const props = defineProps({
  errorMessages: {
    type: [String, Array] as PropType<string | string[]>,
    default: () => []
  }
})

const shift = defineModel<null | number>({ default: null })

const queryFilters = reactive<IShiftFilterParam>({ search: '', page: 1 })

const { shifts, error: fetchError, isLoading } = useFetchShifts(queryFilters)

const combinedErrorMessages = computed(() => {
  let error: string[] = []

  if (fetchError.value) {
    error.push(fetchError.value.message)
  }
  if (Array.isArray(props.errorMessages)) {
    error.push(...props.errorMessages)
  } else {
    error.push(props.errorMessages)
  }

  return error
})

function shiftListItemProps(shift: IShift) {
  return {
    value: shift.id ?? shift,
    title: shift instanceof Shift ? shift.site.toString() : shift,
    subtitle: shift instanceof Shift ? shift.dateRangeFormat() : shift,
    height: 52
  }
}
</script>
