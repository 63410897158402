<template>
  <v-input v-model="modelValue" :v-bind="$attrs" :rules="rules" :error-messages="errorMessages">
    <template #default="{ isDisabled, isValid, isDirty }">
      <v-row>
        <v-col :cols="imageDataURL ? 7 : 12">
          <div
            :disabled="isDisabled.value"
            variant="flat"
            color="transparent"
            class="d-flex flex-grow-1"
            id="app"
          >
            <div
              id="container"
              :class="{ 'container--error': isValid.value === false }"
              ref="target"
              @paste="onPaste"
              style="cursor: pointer"
            >
              Click here then use Control-V to paste the image.
              <br />
              or
              <br />
              <v-btn @click.stop="openFileInput()" class="text-capitalize">Upload File</v-btn>
            </div>
          </div>
        </v-col>
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-col cols="5" v-if="imageDataURL" class="pl-0 pt-2" v-bind="props">
              <div class="d-flex flex-row text-subtitle-2">
                <span class="text-decoration-underline">Preview</span>
                <v-spacer></v-spacer>
                <template v-if="clearable">
                  <v-expand-x-transition key="clear">
                    <div @click.stop.prevent="" v-show="isHovering && isDirty.value">
                      <v-icon @click="modelValue = null" icon="mdi-close" size="20" />
                    </div>
                  </v-expand-x-transition>
                </template>
              </div>
              <div style="height: calc(100% - 70px)">
                <ImageViewer
                  width="100%"
                  :height="140"
                  :src="imageDataURL as string"
                  alt="Pasted Image"
                />
              </div>
            </v-col>
          </template>
        </v-hover>
      </v-row>
    </template>
  </v-input>

  <input
    ref="fileInputRef"
    :accept="accept"
    type="file"
    class="d-none"
    @change="(e) => setFileImage(e)"
  />
</template>

<script setup lang="ts">
import type { ValidationRule } from 'node_modules/v-phone-input/dist/components/VPhoneInput.vue'
import { ref, watch, type PropType } from 'vue'
import ImageViewer from './ImageViewer.vue'

const modelValue = defineModel<File | string | null>()

defineProps({
  accept: {
    type: [String] as PropType<string>
  },
  errorMessages: {
    type: [String, Array, null] as PropType<string | string[] | null>,
    default: () => []
  },
  rules: {
    type: Array as () => readonly ValidationRule[],
    default: () => []
  },
  clearable: { type: Boolean, default: false }
})

const imageDataURL = ref<string | ArrayBuffer | null>(null)
const fileInputRef = ref<HTMLInputElement | null>(null)

function onPaste(event: ClipboardEvent) {
  const clipboardData = event.clipboardData || (window as any).clipboardData.getData('Text') //get clipboard from window if it doesn't get from event
  const items = clipboardData.items
  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf('image') !== -1) {
      modelValue.value = items[i].getAsFile()
    }
  }
}

watch(
  () => modelValue.value,
  (newValue) => {
    if (newValue) {
      //if it is an image url
      if (typeof newValue == 'string') {
        imageDataURL.value = newValue
      } else {
        processImage(newValue)
      }
    } else {
      imageDataURL.value = ''
    }
  },
  { immediate: true }
)

function processImage(imageFile: File) {
  const reader = new FileReader()
  reader.onload = (event) => {
    imageDataURL.value = event.target!.result
  }
  reader.readAsDataURL(imageFile as File)
}

function openFileInput() {
  fileInputRef.value!.click()
}

function setFileImage(e: Event) {
  if (!(e.target as HTMLInputElement)?.files![0]) return
  modelValue.value = (e.target as HTMLInputElement).files![0]
}
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
}

#app img {
  max-width: 100%;
}

#app #container {
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#app #container:hover {
  border-color: #555;
  background-color: #fff;
  color: #333;
}

#app #container:focus {
  outline: none;
}

#app #container.container--error {
  border: 2px dashed rgba(var(--v-theme-error)) !important;
}

#app img {
  margin-top: 20px;
}
</style>
