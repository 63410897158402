<template>
  <v-divider class="position-sticky" style="bottom: 40px" />

  <v-hover v-slot="{ isHovering, props }">
    <v-list-item
      v-bind="{ ...props, ...$attrs }"
      :disabled="!search"
      :color="isHovering ? 'white' : 'primary'"
      :class="hoverListItemClass(!!isHovering)"
      class="d-flex align-center justify-center text-center smooth-transition position-sticky"
      style="bottom: 0px; opacity: 1 !important"
      :style="
        !search
          ? 'color: grey !important'
          : isHovering
            ? 'color: white !important'
            : 'color: black !important'
      "
    >
      <v-list-item-title>
        <v-icon
          size="20"
          small
          class="mr-1 mb-1"
          :color="!search ? 'grey' : isHovering ? 'white' : 'primary'"
        >
          mdi-plus-circle-outline </v-icon
        ><span style="color: blue !important"></span>{{ label }}
        <span v-if="search">"{{ search }}"</span>
      </v-list-item-title>
    </v-list-item>
  </v-hover>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

defineOptions({
  inheritAttrs: false
})

interface Props {
  search: string
  label: string
}
const props = defineProps<Props>()

function hoverListItemClass(isHovering: boolean) {
  return !props.search
    ? 'bg-white grey--text'
    : isHovering
      ? 'bg-primary white--text'
      : 'bg-white primary--text'
}
</script>
