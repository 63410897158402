<template>
  <BasePage title="Vehicles Types" subtitle="Manage vehicles types information">
    <template #page-header:actions>
      <v-btn
        color="primary"
        depressed
        v-if="currentUser?.hasPermission('fleet.add_vehiclemake')"
        @click="openVehicleMakeFormDialog()"
      >
        <template #prepend>
          <v-icon start class="hidden-sm-and-down me-0"> {{ route.meta.icon }} </v-icon>
        </template>
        Create Vehicle Make
      </v-btn>
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat height="calc(100vh - 286px)" class="px-4 rounded-lg">
      <v-toolbar flat height="84" color="transparent">
        <v-text-field
          label="Search"
          single-line
          hide-details="auto"
          variant="outlined"
          density="comfortable"
          style="max-width: 400px"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
        />
      </v-toolbar>

      <VehicleTypeList
        :loading="isLoading"
        :vehiclesMakes="vehicleMakes ?? []"
        :search="search"
        @vehicle-make-selected="openVehicleMakeDetailDrawer"
        @vehicle-make-edit-pressed="openVehicleMakeFormDialog"
        @vehicle-make-delete-pressed="openDeleteVehicleDialog"
      />
    </v-card>

    <ConfirmationDialog
      v-if="selectedVehicleMake"
      width="500"
      title="Delete vehicle make"
      v-model="vehicleDeleteDialog"
      v-model:error="deleteError"
    >
      <template #message>
        Are you sure you want to delete this vehicle make?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          variant="flat"
          :disabled="deleteInProgress"
          :loading="deleteInProgress"
          @click="removeVehicle(selectedVehicleMake!)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BasePage>

  <VehicleModelDetailDrawer
    v-if="selectedVehicleMake"
    v-model:drawer="rightDrawer"
    :vehicle-make="selectedVehicleMake"
    @vehicle-make-edit-pressed="
      (vehicleMake: IVehicleMake) => openVehicleMakeFormDialog(vehicleMake)
    "
    @vehicle-make-delete-pressed="openDeleteVehicleDialog"
  />

  <VehicleMakeFormDialog
    v-model:dialog="isVehicleMakeFormDialogActive"
    :vehicleMake="selectedVehicleMake!"
    :isEdit="!!selectedVehicleMake"
    ref="vehicleFormDialogRef"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useAuthStore } from '@/stores'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'

import type { IVehicleMake } from '@/models/fleet-management'
import { useDeleteVehicleMake, useFetchVehicleMakes } from '@/composables/vehicle-make'

import BasePage from '@/components/base/BasePage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'

import VehicleMakeFormDialog from '@/components/fleet-management/vehicles-types/VehicleMakeFormDialog.vue'
import VehicleModelDetailDrawer from '@/components/fleet-management/vehicles-types/VehicleTypeDetailDrawer.vue'
import VehicleTypeList from '@/components/fleet-management/vehicles-types/VehicleTypeList.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'

const route = useRoute()

const rightDrawer = ref(false)

function openVehicleMakeDetailDrawer(vehicleMake: IVehicleMake) {
  selectedVehicleMake.value = vehicleMake

  rightDrawer.value = true
}

const search = ref<string>('')

const selectedVehicleMake = ref<IVehicleMake>()

const isVehicleMakeFormDialogActive = ref(false)

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

function openVehicleMakeFormDialog(vehicle?: IVehicleMake) {
  selectedVehicleMake.value = vehicle

  isVehicleMakeFormDialogActive.value = true
}

const vehicleDeleteDialog = ref(false)

function openDeleteVehicleDialog(vehicle: IVehicleMake) {
  selectedVehicleMake.value = vehicle
  vehicleDeleteDialog.value = true
}

function closeDrawerAndDialog() {
  vehicleDeleteDialog.value = false
  rightDrawer.value = false
}

// CORE
const { vehicleMakes, isLoading, error } = useFetchVehicleMakes()

function clearErrors() {
  error.value = null
}
const {
  isPending: deleteInProgress,
  error: deleteError,
  mutate: deleteVehicleMake
} = useDeleteVehicleMake()

function removeVehicle(vehicle: IVehicleMake) {
  deleteVehicleMake(vehicle.id!, {
    onSuccess: () => {
      closeDrawerAndDialog()
    }
  })
}
</script>

<style lang="scss">
.site-list .v-list-item--three-line .v-list-item-subtitle {
  -webkit-line-clamp: 5;
}

.card-list {
  --v-card-list-gap: 0.25rem;

  &.v-list {
    padding-block: 0;
  }

  .v-list-item {
    min-block-size: unset;
    min-block-size: auto !important;
    padding-block: 0 !important;
    padding-inline: 0 !important;

    > .v-ripple__container {
      opacity: 0;
    }

    &:not(:last-child) {
      padding-block-end: var(--v-card-list-gap) !important;
    }
  }

  .v-list-item:hover,
  .v-list-item:focus,
  .v-list-item:active,
  .v-list-item.active {
    > .v-list-item__overlay {
      opacity: 0 !important;
    }
  }
}
</style>
