import { AbstractModel } from '@/models/base'
import type { IFormSection, IFormSectionData } from './form-section.types'

/**
 * A class representing an FormSection.
 */
export class FormSection extends AbstractModel implements IFormSection {
  readonly id?: number
  readonly title: string
  readonly description: string = ''
  readonly order: number

  constructor(data: IFormSectionData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormSectionData)[] = ['title', 'order']
    this.validate(data, requiredFields)

    this.title = data.title!
    this.order = data.order!

    if (data.id) {
      this.id = data.id
    }

    if (data.description) {
      this.description = data.description
    }
  }

  toString(): string {
    return this.title
  }
}
