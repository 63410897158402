<template>
  <BaseRightDrawer v-model:drawer="drawer" @right-drawer-close="drawer = false">
    <template #header-before-close v-if="vehicleMake">
      <VehicleMakeDetailOptionsButton
        class="me-4"
        :vehicle-make="vehicleMake"
        @vehicle-make-edit-pressed="
          (vehicleMake: IVehicleMake) => emit('vehicle-make-edit-pressed', vehicleMake)
        "
        @vehicle-make-delete-pressed="
          (vehicleMake: IVehicleMake) => emit('vehicle-make-delete-pressed', vehicleMake)
        "
      />
    </template>

    <div class="d-flex flex-column flex-grow-1" v-if="vehicleMake">
      <v-list-item lines="three" class="position-relative rounded-lg mx-6 mb-2 px-0 pb-1 pt-0">
        <v-list-item-action>
          <v-avatar
            variant="flat"
            color="secondary"
            size="74"
            class="mb-6 px-3 text-h4"
            style="border-radius: 5px"
          >
            <span class="text-white font-weight-medium">{{ initials }}</span></v-avatar
          >
        </v-list-item-action>
        <v-list-item-title class="text-high-emphasis text-h4 font-weight-bold pt-1 pb-8">
          {{ vehicleMake.name }}
        </v-list-item-title>
      </v-list-item>

      <v-divider class="mb-4 mx-6 text-medium-emphasis" thickness="2" />

      <div class="d-flex flex-column">
        <VehicleModelList :vehicle-make-id="vehicleMake.id!" />
      </div>
    </div>
  </BaseRightDrawer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { IVehicleMake } from '@/models/fleet-management'

import BaseRightDrawer from '@/components/base/BaseRightDrawer.vue'
import VehicleMakeDetailOptionsButton from './VehicleMakeDetailOptionsButton.vue'
import VehicleModelList from './VehicleModelList.vue'

interface Props {
  vehicleMake: IVehicleMake
}
const props = defineProps<Props>()

interface Emit {
  (e: 'vehicle-make-edit-pressed', vehicleMake: IVehicleMake): void
  (e: 'vehicle-make-delete-pressed', vehicleMake: IVehicleMake): void
}
const emit = defineEmits<Emit>()

const drawer = defineModel<boolean>('drawer')

const initials = computed(() =>
  //gets first letter of the first and last words
  {
    const names = props.vehicleMake.name.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }
)
</script>

<style lang="scss">
.al-list .v-list-item--three-line .v-list-item-subtitle {
  -webkit-line-clamp: 5;
}

.al-subheader {
  .v-list-subheader__text {
    align-items: center;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  }
}

.detail-item-subtitle {
  opacity: 1;
}
</style>
